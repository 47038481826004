import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { ButtonProps } from './Button.types'

import styles from './Button.module.scss'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { active, variant, size, block, className, children, disabled, ...restProps } = props
  const cx = useStyles(styles)

  const classNames = cx(className, 'container', {
    block,
    disabled,
    active,
    [`size-${size}`]: size,
    [`variant-${variant}`]: variant,
  })

  return (
    <button data-testid="Button" ref={ref} className={classNames} disabled={disabled} {...restProps}>
      {children}
    </button>
  )
})

Button.defaultProps = {
  variant: 'primary',
  size: 32,
  block: false,
}

export default Button
