import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { format, parseISO } from 'date-fns'
import { useStore } from 'hooks/useStore'
import { dateFormat, dateNow, sevenDaysEarly, today, yesterday } from 'lib/dates'

import DateInput from 'components/containers/DateInput'
import Autocomplete from 'components/elements/Autocomplete'
import QuickSelectButton from 'components/containers/QuickSelectButton'

import { StatisticsFormType } from './StatisticsForm.types'
import { DateRange } from 'components/containers/DateInput/DateInput.types'

import styles from './StatisticsForm.module.scss'

const quickDateSelectOptions = [
  { label: 'Вчера', value: `${yesterday}|${yesterday}` },
  { label: 'Сегодня', value: `${today}|${today}` },
  { label: 'Неделя', value: `${sevenDaysEarly}|${yesterday}` },
]

const StatisticsForm: React.FC<StatisticsFormType> = ({ siteOptions, onSubmit }) => {
  const [selectedDateRange, setSelectedDateRange] = React.useState<DateRange>()

  const {
    statistic: { config, setConfig, dateRange, setDateRange },
  } = useStore()

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm()

  const siteInput = watch('site')
  const dateInput = watch('dates')

  const handleQuickDateSelect = (range: string | number) => {
    const rangeData = range.toString().split('|')
    setSelectedDateRange({
      from: parseISO(rangeData[0]),
      to: parseISO(rangeData[1]),
    })
  }

  const activeValue = React.useMemo(
    () => `${dateRange?.date_start || today}|${dateRange?.date_end || today}`,
    [dateRange]
  )

  React.useEffect(() => {
    if (siteInput) {
      config &&
        setConfig({
          ...config,
          sites_ids: siteInput.join(','),
        })
    } else {
      dateRange &&
        setDateRange({
          ...dateRange,
        })
    }
  }, [siteInput])

  React.useEffect(() => {
    dateInput &&
      setDateRange({
        date_start: format(dateInput.from, dateFormat),
        date_end: format(dateInput.to, dateFormat),
      })
  }, [dateInput])

  React.useEffect(() => {
    selectedDateRange && setValue('dates', selectedDateRange)
  }, [selectedDateRange])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <div className={styles.sitesBlock}>
        <Controller
          defaultValue={''}
          name="site"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              options={siteOptions}
              placeholder="Все сайты"
              error={errors.site ? 'Обязательное поле' : ''}
              {...field}
            />
          )}
        />
      </div>

      <div className={styles.datesBlock}>
        <div className={styles.quickDateTemplates}>
          <label className={styles.label}>{'Показать статистику за:'}</label>
          <QuickSelectButton
            activeButtonValue={activeValue}
            options={quickDateSelectOptions}
            onSelect={handleQuickDateSelect}
          />
        </div>
        <div className={styles.dataPickerWrapper}>
          <label>{'Выбрать период:'}</label>
          <Controller
            defaultValue={{ from: dateNow, to: dateNow }}
            name="dates"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DateInput
                range={true}
                // error={errors.site ? 'Обязательное поле' : ''}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}

export default StatisticsForm
