import React from 'react'
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import { useStyles } from 'hooks/useStyles'

import ControlInput from 'components/containers/ControlInput'

import { DateInputProps } from './DateInput.types'

import styles from './DateInput.module.scss'

const DatePickerContainer = ({ className, children }: any) => {
  const cx = useStyles(styles)
  const calendar = cx(className, 'calendar')

  return (
    <div className={cx('calendarContainer')}>
      <CalendarContainer className={calendar}>{children}</CalendarContainer>
    </div>
  )
}

const DateInput = React.forwardRef<HTMLDivElement, DateInputProps>((props, ref) => {
  const { value, className, children, disabled, dateFormat, placement, range, placeholder, onChange, ...restProps } =
    props

  const [startDate, setStartDate] = React.useState<Date | null>(null)
  const [endDate, setEndDate] = React.useState<Date | null>(null)

  registerLocale('ru', ru)

  const cx = useStyles(styles)
  const dateInput = cx(className, 'dateInputContainer')

  const withTime = React.useMemo(() => dateFormat === 'dd/MM/yyyy, p', [dateFormat])

  const handleChange = (dates: Array<Date | null> | Date | null) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates

      setStartDate(start)
      setEndDate(end)
      onChange && start && end && onChange({ from: start, to: end })
    } else {
      setStartDate(dates)
      setEndDate(dates)
      onChange && onChange(dates ?? undefined)
    }
  }

  React.useEffect(() => {
    if (value === undefined || value === null) {
      setStartDate(null)
      setEndDate(null)

      return
    }
    if (value instanceof Date) {
      setStartDate(value)
      setEndDate(value)

      return
    }

    setStartDate(value.from)
    setEndDate(value.to)
  }, [value])

  return (
    <div className={styles.wrapper} ref={ref}>
      <DatePicker
        data-testid="DateInput"
        dateFormat={dateFormat}
        className={dateInput}
        dayClassName={() => styles.days}
        weekDayClassName={() => styles.weekdays}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        showTimeInput={withTime}
        selectsRange={range}
        onChange={handleChange}
        customInput={<ControlInput />}
        locale="ru"
        calendarContainer={DatePickerContainer}
        popperPlacement={placement}
        placeholderText={placeholder}
      />
    </div>
  )
})

DateInput.defaultProps = {
  dateFormat: 'dd/MM/yyyy',
  placement: 'bottom-start',
  range: false,
}

export default DateInput
