import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { CampaignApi, PlaceApi, SiteApi } from 'api'
import { makeOptions } from 'lib/utils'

import PlacesForm from 'components/forms/PlacesForm'
import Notification from 'components/elements/Notification'
import Breadcrumbs from 'components/containers/Breadcrumbs'

import { SelectOptions } from 'types/selectOptions'
import { PlaceData } from 'types/placeData'

import styles from '../places.module.scss'

// TODO: будет формироваться на бэке динамически
const templateFormats = ['240х400', '728х90', '600х200']

const AddPlace: React.FC = () => {
  const [siteOptions, setSiteOptions] = React.useState<SelectOptions[]>([])
  const [campaignOptions, setCampaignOptions] = React.useState<SelectOptions[]>([])
  const [error, setError] = React.useState<string>('')

  const navigate = useNavigate()

  const getSitesList = async () => {
    const { response, error } = await SiteApi.list({ pagination_per_page: 999 })

    response && setSiteOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const getCampaignList = async () => {
    const { response, error } = await CampaignApi.list({ pagination_per_page: 999 })

    response && setCampaignOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const submitHandler = async (data: PlaceData) => {
    const { site_id, name, campaign_id, ...rest } = data
    const payload = campaign_id ? data : { site_id, name, ...rest }
    const { response, error } = await PlaceApi.add({ ...payload, ...{ height_size_type: 'px' } })

    response && navigate(-1)
    error && setError(error.message)
  }

  React.useEffect(() => {
    getSitesList()
    getCampaignList()
  }, [])

  return (
    <>
      <Breadcrumbs route={routes.campaigns.add} routeGroup={routes.places} setError={setError} />
      <PlacesForm
        formats={templateFormats}
        siteOptions={siteOptions}
        campaignOptions={campaignOptions}
        onSubmit={submitHandler}
      />
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default AddPlace
