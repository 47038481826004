import React from 'react'
import { UserApi } from 'api'
import { useAuth } from 'hooks/useAuth'
import { UserData } from 'types/userData'
import { UserDataProviderType } from './UserDataProvider.types'

const getUser = async (id: number) => {
  if (!id) return
  const { response } = await UserApi.get(id)

  if (response) return response
}

/**
 * User data context provider
 *
 * @param children is a consumer of a context
 * @returns a context provider with user data
 */

export const UserDataContext = React.createContext({} as UserDataProviderType)

export const UserDataProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = React.useState<UserData | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const isFirstRender = React.useRef(true)
  const { userId } = useAuth()

  React.useEffect(() => {
    if (isFirstRender.current && userId) {
      setIsLoading(true)
      getUser(userId).then(data => {
        setUserData(data)
        setIsLoading(false)
      })

      isFirstRender.current = false
    }
  }, [userId])

  return <UserDataContext.Provider value={{ userData, isLoading }}>{children}</UserDataContext.Provider>
}
