import React from 'react'
import { MirrorApi } from 'api'
import AddMirrorForm from 'components/forms/InlineForm'
import MenuItem from 'components/containers/MenuItem'
import Button from 'components/elements/Button'
import Loader from 'components/elements/Loader'
import { InlineFormData } from 'types/inlineFormData'
import { MirrorModalContentProps } from './mirrorModalContent.types'

import styles from './mirrorModalContent.module.scss'

// TODO: хардкор до момента React-query
type mirrorsArr = {
  id: number
  host: string
}

const MirrorModalContent: React.FC<MirrorModalContentProps> = ({ siteId, setError }) => {
  const [mirrorsList, setMirrorsList] = React.useState<Array<mirrorsArr>>([])
  const [responseError, setResponseError] = React.useState<string>('')

  const getMirrors = async (id: number) => {
    const { response, error } = await MirrorApi.list(id)

    response && setMirrorsList(response.items)
    error && setError(error.message)
  }

  const addMirror = async (payload = {}) => {
    setResponseError('')
    const { response, error } = await MirrorApi.add(payload)

    if (response) {
      getMirrors(siteId)

      return response
    }

    error && setResponseError(error.message)
  }

  const deleteMirror = async (id: number) => {
    const { response, error } = await MirrorApi.delete(id)

    response && getMirrors(siteId)
    error && setError(error.message)
  }

  const handleAddMirror = (data: InlineFormData) => {
    return addMirror({ url: data.field, site_id: siteId })
  }

  const handleDeleteMirror = (id: number) => {
    deleteMirror(id)
  }

  React.useEffect(() => {
    getMirrors(siteId)
  }, [siteId])

  return (
    <>
      <p className={styles.title}>Зеркала</p>
      <AddMirrorForm
        onSubmit={handleAddMirror}
        textButton={'Добавить зеркало'}
        placeholder={'Введите URL'}
        error={responseError}
      />
      <div className={styles.mirrorsList}>
        {mirrorsList.length ? (
          mirrorsList.map((mirror, index: React.Key) => (
            <MenuItem text={mirror.host} className={styles.mirror} key={index}>
              <Button variant="secondary" className={styles.delButton} onClick={() => handleDeleteMirror(mirror.id)}>
                Удалить
              </Button>
            </MenuItem>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </>
  )
}

export default MirrorModalContent
