import { request } from 'api/request'

export default class AdmApi {
  static async config() {
    return request.get(`cmd/adm/config`, {})
  }

  static async suggestGeo<T>(params: T) {
    return request.get(`cmd/adm/suggestGeo`, { ...params })
  }

  static async fillGeo<T>(params: T) {
    return request.get(`cmd/adm/fillGeo`, { ...params })
  }

  static async suggestStatSites<T>(params: T) {
    return request.get(`cmd/adm/suggestStatSites`, { ...params })
  }
}
