import React from 'react'
import { routes } from 'routes/routes'
import { StatisticApi, AdmApi } from 'api'
import { useStyles } from 'hooks/useStyles'
import { useStore } from 'hooks/useStore'
import { extractValues, makeBannersData, makeCampaignsData, restoreState, saveState } from 'lib/statistic'
import { makeOptions } from 'lib/utils'

import Table from 'components/containers/Table'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import Notification from 'components/elements/Notification'
import StatisticsForm from 'components/forms/StatisticsForm'
import Head from './TableHead/Head'
import Cell from './TableCell/StatisticCell'
import ActiveCell from './TableCell/ActiveCell'

import { SelectOptions } from 'types/selectOptions'
import { Option } from './TableHead/OptionButtons/OptionButtons.types'
import { SiteData } from 'types/siteData'
import { StatisticConfig } from './statistic.types'
import { ExtraInfo } from './TableCell/StatisticCell/StatisticCell.types'

import styles from './statistic.module.scss'

const tableHead: { [key: string]: Option } = {
  loads: { label: 'Показы', value: 'loads' },
  views_all: { label: 'Просмотры', value: 'views_all' },
  clicks: { label: 'Клики', value: 'clicks' },
  ctr: { label: 'CTR', value: 'ctr' },
  profit: { label: 'Доход', value: 'profit' },
}

const tableConfig: any = [
  {
    title: 'Сайт',
    name: 'name',
    render: (value: string, extra: ExtraInfo) => <Cell value={value} extra={extra || {}} />,
    width: 280,
  },
  { title: 'Размер', name: 'formatName', width: 100 },
  { title: 'Рекламное место', name: 'placeName', width: 180 },
  {
    title: <Head options={tableHead.loads} position="right" />,
    name: 'loads',
    width: 110,
    align: 'right',
    render: (value: string) => <ActiveCell value={value.toLocaleString()} column="loads" />,
  },
  {
    title: <Head options={tableHead.views_all} withSortDirection />,
    name: 'views_all',
    width: 130,
    render: (value: string) => <ActiveCell value={value.toLocaleString()} column="views_all" />,
  },
  {
    title: <Head options={tableHead.clicks} position="right" />,
    name: 'clicks',
    width: 70,
    align: 'right',
    render: (value: string) => <ActiveCell value={value.toLocaleString()} column="clicks" />,
  },
  {
    title: <Head options={tableHead.ctr} withSortDirection />,
    name: 'ctr',
    width: 80,
    render: (value: string) => <ActiveCell value={value} column="ctr" />,
  },
  {
    title: <Head options={tableHead.profit} withSortDirection />,
    name: 'profit',
    width: 100,
    render: (value: string) => <ActiveCell value={value.toLocaleString()} column="profit" />,
  },
]

const Statistic: React.FC = () => {
  const [statistic, setStatistic] = React.useState<any>([])
  const [siteOptions, setSiteOptions] = React.useState<SelectOptions[]>([])
  const [error, setError] = React.useState<string>('')
  const state = React.useRef<any[]>([])

  const {
    statistic: { config, setConfig, dateRange },
  } = useStore()

  const getStatistic = async (payload: StatisticConfig) => {
    const { response, error } = await StatisticApi.get(payload)

    response && setStatistic(extractValues(makeCampaignsData(response.sites)))
    error && setError(error.message)
  }

  const getStatSites = async () => {
    const { response, error } = await AdmApi.suggestStatSites(dateRange)

    if (response) {
      setSiteOptions(makeOptions(response.items, 'name', 'id'))
      const sites_ids = response.items.map((site: SiteData) => site.id).join(',')

      if (config && sites_ids) {
        setConfig({ ...config, sites_ids })
      } else {
        setStatistic([])
      }
    }

    error && setError(error.message)
  }

  const handleCampaignCellClick = (e: any) => {
    const campaignId = e.target.dataset.campaignid
    if (!campaignId) return

    setStatistic(extractValues(makeBannersData(statistic, campaignId)))
  }

  React.useEffect(() => {
    if (!config?.sites_ids) return
    state.current = saveState(statistic)
    config && dateRange && getStatistic({ ...config, ...dateRange })
  }, [config])

  React.useEffect(() => {
    if (state.current.length) {
      setStatistic(extractValues(restoreState(statistic, state.current)))
      state.current = []
    }
  }, [statistic])

  React.useEffect(() => {
    dateRange && getStatSites()
  }, [dateRange])

  return (
    <>
      <Breadcrumbs route={routes.statistic.index} routeGroup={routes.statistic} setError={setError} />
      <StatisticsForm siteOptions={siteOptions} onSubmit={e => e} />
      <Table
        tConfig={tableConfig}
        data={statistic}
        emptyMessage={'Нет данных за выбранный период'}
        className={styles.customizeTable}
        withControl={false}
        onClick={handleCampaignCellClick}
      />

      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Statistic
