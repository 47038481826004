import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { InputProps } from './Input.types'

import styles from './Input.module.scss'

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { size, invalid, disabled, className, ...restProps } = props
  const cx = useStyles(styles)

  const input = cx(className, 'container', {
    invalid,
    disabled,
    [`size-${size}`]: size,
  })

  return <input data-testid="Input" ref={ref} className={input} disabled={disabled} autoComplete="off" {...restProps} />
})

Input.defaultProps = {
  size: 32,
}

export default Input
