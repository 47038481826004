import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStyles } from 'hooks/useStyles'

import { deviceTargeting, geoTargeting } from 'constant/targeting'

import CheckboxGroup from 'components/containers/CheckboxGroup'
import Button from 'components/elements/Button'
import ControlInput from 'components/containers/ControlInput'
import Autocomplete from 'components/elements/Autocomplete'
import WeeklyTimePicker from 'components/containers/WeeklyTimePicker'
import Tooltip from 'components/elements/Tooltip'

import { TargetingFormProps } from './TargetingForm.types'

import styles from './TargetingForm.module.scss'

const quickWeeklyTimerTemplates = [
  { label: 'В будни', value: 0 },
  { label: 'В выходные', value: 1 },
  { label: '24/7', value: 2 },
]

const TargetingForm: React.FC<TargetingFormProps> = ({
  defaultGeoOptions,
  geoOptions,
  targetingData,
  error,
  onGeoQuery,
  onSubmit,
  onChange,
}) => {
  const navigate = useNavigate()

  const {
    control,
    watch,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()

  const [disabled, setDisabled] = React.useState<boolean>(!getValues('countries'))
  const countries = watch('countries')

  const cx = useStyles(styles)

  const handleClickQuickTemplate = (input: string, value: unknown) => {
    const currentValue = getValues(input) || []
    if (currentValue.find((o: unknown) => o === value)) return

    setValue(input, [...currentValue, value])
  }

  const handleClickWeeklyTimerTemplates = (value: number) => {
    const allTimeRanges = Array.from(Array(24).keys())
    switch (value) {
      case 0:
        setValue('config_day_and_hour', {
          Monday: allTimeRanges,
          Tuesday: allTimeRanges,
          Wednesday: allTimeRanges,
          Thursday: allTimeRanges,
          Friday: allTimeRanges,
        })
        break
      case 1:
        setValue('config_day_and_hour', { Sunday: allTimeRanges, Saturday: allTimeRanges })
        break
      case 2:
        setValue('config_day_and_hour', '')
        break
    }
  }

  const submitInterceptor = (data: any) => {
    onSubmit(data)
  }

  React.useEffect(() => {
    if (!countries) {
      for (const type of geoTargeting) {
        type !== 'countries' && setValue(type, undefined)
      }
    }

    setDisabled(!countries)
  }, [countries])

  React.useEffect(() => {
    if (targetingData?.config_geo) {
      for (const type of geoTargeting) {
        //@ts-ignore
        type !== 'countries' && setValue(type, targetingData.config_geo[type])
      }
    }
  }, [targetingData])

  React.useEffect(() => {
    error && setError('field', { type: 'api', message: error })
  }, [error])

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(submitInterceptor)} className={styles.form}>
        <label className={styles.label}>{'Название таргетинга'}</label>
        <Controller
          defaultValue={targetingData?.name || ''}
          name="name"
          control={control}
          rules={{ required: 'Обязательное поле' }}
          render={({ field }) => (
            <ControlInput placeholder="Введите название" {...field} error={errors.name?.message} />
          )}
        />

        <div className={styles.keywordsLabel}>
          <label>{'Тэги'}</label>
          <Tooltip>
            {'Реклама показывается на страницах совпадения\n тэгов с URL, title, description, keywords'}
          </Tooltip>
        </div>
        <Controller
          defaultValue={targetingData?.config_keywords || ''}
          name="config_keywords"
          control={control}
          render={({ field }) => (
            <Autocomplete
              className={styles.keywordsInput}
              withOptions={false}
              error={errors.name?.message}
              {...field}
            />
          )}
        />

        <div className={styles.osLabel}>
          <label>{'Операционные системы'}</label>
          <Tooltip>{'Если ничего не выбрано — показывается\n для всех ОС'}</Tooltip>
        </div>
        <Controller
          name="config_os"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CheckboxGroup
              className={styles.osCheckboxGroup}
              checkboxConfig={deviceTargeting}
              data={targetingData ? targetingData.config_os : []}
              errorMessage={errors.config_os?.message}
              {...field}
            />
          )}
        />

        <div className={styles.geoLabel}>
          <label>{'Геотаргетинг'}</label>
          <Tooltip>{'Таргетирование рекламы по географическому\n положению пользователя'}</Tooltip>
        </div>
        <div className={styles.geoBlockWrapper}>
          <div className={styles.geoBlock}>
            <div className={styles.selectWrapper}>
              <label className={styles.label}>Страны</label>
              <Controller
                defaultValue={targetingData?.config_geo?.countries || undefined}
                name="countries"
                control={control}
                rules={{ required: false }}
                render={({ field: { onBlur, ...rest } }) => (
                  <Autocomplete
                    options={geoOptions?.countries || defaultGeoOptions?.countries}
                    placeholder="Введите название"
                    onSearch={query => onGeoQuery({ query, types: 'country' })}
                    error={errors.countries?.message}
                    onBlur={onChange}
                    {...rest}
                  />
                )}
              />
            </div>
            <div className={styles.quickTemplateWrapper}>
              <label className={styles.label}>{'Быстрый набор'}</label>
              <div className={styles.templates}>
                {defaultGeoOptions?.countries.map(template => (
                  <Button
                    type="button"
                    variant="text"
                    onClick={() => handleClickQuickTemplate('countries', template.value)}
                    key={template.value}
                  >
                    {template.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.geoBlock}>
            <div className={styles.selectWrapper}>
              <label className={styles.label}>{'Регионы'}</label>
              <Controller
                defaultValue={targetingData?.config_geo?.regions || undefined}
                name="regions"
                control={control}
                rules={{ required: false }}
                render={({ field: { onBlur, ...rest } }) => (
                  <Autocomplete
                    disabled={disabled}
                    options={geoOptions?.regions || defaultGeoOptions?.regions}
                    placeholder="Введите название"
                    onSearch={query => onGeoQuery({ query, types: 'region', countriesIds: getValues('countries') })}
                    onBlur={onChange}
                    error={errors.regions?.message}
                    {...rest}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.geoBlock}>
            <div className={styles.selectWrapper}>
              <label className={styles.label}>{'Города'}</label>
              <Controller
                defaultValue={targetingData?.config_geo?.cities || undefined}
                name="cities"
                control={control}
                rules={{ required: false }}
                render={({ field: { onBlur, ...rest } }) => (
                  <Autocomplete
                    disabled={disabled}
                    options={geoOptions?.cities || defaultGeoOptions?.cities}
                    placeholder="Введите название"
                    onSearch={query => onGeoQuery({ query, types: 'city', countriesIds: getValues('countries') })}
                    onBlur={onChange}
                    error={errors.cities?.message}
                    {...rest}
                  />
                )}
              />
            </div>
            <div className={styles.quickTemplateWrapper}>
              <label className={styles.label}>{'Быстрый набор'}</label>
              <div className={styles.templates}>
                {defaultGeoOptions?.cities.map(template => (
                  <Button
                    disabled={disabled}
                    type="button"
                    variant="text"
                    onClick={() => handleClickQuickTemplate('cities', template.value)}
                    key={template.value}
                  >
                    {template.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.geoBlock}>
            <div className={styles.selectWrapper}>
              <label className={styles.label}>{'Исключения'}</label>
              <Controller
                defaultValue={targetingData?.config_geo?.cities_exclude || undefined}
                name="cities_exclude"
                control={control}
                rules={{ required: false }}
                render={({ field: { onBlur, ...rest } }) => (
                  <Autocomplete
                    disabled={disabled}
                    options={geoOptions?.cities}
                    placeholder="Введите название"
                    onSearch={query => onGeoQuery({ query, types: 'city', countriesIds: getValues('countries') })}
                    onBlur={onChange}
                    error={errors.cities_exclude?.message}
                    {...rest}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className={styles.weeklyTimer}>
          <label className={styles.weeklyTimerLabel}>{'Время показов'}</label>
          <Tooltip className={styles.weeklyTimerTooltip}>
            {'Оставьте таблицу пустой, чтобы объявление \n работало 24/7. Часовой пояс МСК'}
          </Tooltip>
          <div className={styles.templates}>
            {quickWeeklyTimerTemplates.map(template => (
              <Button
                type="button"
                variant="text"
                onClick={() => handleClickWeeklyTimerTemplates(template.value)}
                key={template.label}
              >
                {template.label}
              </Button>
            ))}
          </div>
        </div>
        <Controller
          defaultValue={targetingData?.config_day_and_hour || ''}
          name="config_day_and_hour"
          control={control}
          rules={{ required: false }}
          render={({ field }) => <WeeklyTimePicker {...field} />}
        />

        <div className={styles.loadLimitLabel}>
          <label>{'Уникальные показы'}</label>
          <Tooltip>{'Количество уникальных показов\n одному пользователю в сутки'}</Tooltip>
        </div>
        <div className={styles.loadLimitWrapper}>
          <span>{'Не более'}</span>
          <Controller
            defaultValue={targetingData?.config_load_limit?.load_limit || ''}
            name="config_load_limit"
            control={control}
            render={({ field }) => (
              <ControlInput className={styles.loadLimitInput} {...field} error={errors.name?.message} />
            )}
          />
          <span>{'показов в сутки'}</span>
        </div>

        <div className={styles.buttons}>
          <Button type="button" onClick={() => navigate(-1)} variant="secondary">
            {'Отмена'}
          </Button>
          <Button type="submit">{!targetingData ? 'Создать' : 'Сохранить'}</Button>
        </div>
      </form>
    </div>
  )
}

export default TargetingForm
