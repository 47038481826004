import { SortSettings } from 'providers/Store/StoreProvider.types'

export const SORT_SETTINGS = 'krut-io-prefer-sort-direction'
export const DEFAULT_SORT_SETTINGS: SortSettings = {
  banners: 'desc',
  places: 'desc',
  campaigns: 'desc',
}

/** Function gets sort direction settings from localStorage
 *
 * @param none
 * @returns object with sort direction settings or default settings
 */
export const getPreferSortDirection = () => {
  const sortDirection = localStorage.getItem(SORT_SETTINGS)

  if (sortDirection) return JSON.parse(sortDirection)

  return DEFAULT_SORT_SETTINGS
}

/** Function saves sort direction settings to localStorage
 *
 * @param settings object with sort direction settings
 * @returns none
 */
export const setPreferSortDirection = (settings: SortSettings) => {
  localStorage.setItem(SORT_SETTINGS, JSON.stringify(settings))
}
