import React from 'react'
import { NavLink } from 'react-router-dom'
import { useStyles } from 'hooks/useStyles'
import MenuItem from 'components/containers/MenuItem'
import { navMenuItems } from 'constant/navMenuItems'
import { NavMenuProps } from './NavMenu.types'

import styles from './NavMenu.module.scss'

const NavMenu: React.FC<NavMenuProps> = ({ className, children, ...restProps }) => {
  const cx = useStyles(styles)

  const container = cx(className, 'container')
  const linkStyle = (isActive: boolean, disabled: boolean) =>
    cx('link', {
      activeLink: isActive,
      disabled,
    })

  return (
    <nav data-testid="NavMenu" className={container} {...restProps}>
      {navMenuItems.map(navMenuItem => (
        <NavLink
          to={navMenuItem.to}
          className={({ isActive }) => linkStyle(isActive, navMenuItem.disabled)}
          key={navMenuItem.text}
        >
          <MenuItem text={navMenuItem.text} icon={navMenuItem.icon} disabled={navMenuItem.disabled} />
        </NavLink>
      ))}
    </nav>
  )
}

NavMenu.defaultProps = {}

export default NavMenu
