import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { useStore } from 'hooks/useStore'
import { PlaceApi } from 'api'

import Paginator from 'components/containers/Table/Paginator'
import Button from 'components/elements/Button'
import Notification from 'components/elements/Notification'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import PlaceTable from './table/PlaceTable'

import { PlaceData } from 'types/placeData'
import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'

import styles from './places.module.scss'

const Places: React.FC = () => {
  const navigate = useNavigate()
  const {
    sort: { places: sortDirection },
  } = useStore()

  const [placeList, setPlaceList] = React.useState<PlaceData[] | null>(null)
  const [pagination, setPagination] = React.useState({} as PaginationData)
  const [error, setError] = React.useState<string>('')

  const [searchParams] = useSearchParams()
  const pagination_page = Number(searchParams.get('page'))
  const pagination_per_page = Number(searchParams.get('per'))

  const getPlaces = async (params = { pagination_page, pagination_per_page }) => {
    const { response, error } = await PlaceApi.list({ ...params, sort: `id_${sortDirection}` })

    if (response) {
      setPlaceList(response.items)
      setPagination(response.paginator)
    }

    error && setError(error.message)
  }

  React.useEffect(() => {
    getPlaces()
  }, [sortDirection])

  return (
    <>
      <Breadcrumbs route={routes.places.index} routeGroup={routes.places} setError={setError} />
      <Button onClick={() => navigate('add')}>{'Добавить'}</Button>
      <PlaceTable placeList={placeList} getPlaces={getPlaces} setError={setError} />
      <Paginator pagination={pagination} paginationFn={getPlaces} />
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Places
