import React from 'react'
import { useForm, Controller } from 'react-hook-form'

import ControlInput from 'components/containers/ControlInput'
import Button from 'components/elements/Button'

import { AuthData } from 'types/authData'
import { AuthFormProps } from './AuthForm.type'

import styles from './AuthForm.module.scss'

const AuthForm: React.FC<AuthFormProps> = ({ responseError, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthData>()

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container} data-testid="AuthForm">
      <label className={styles.labelLogin}>Логин</label>
      <Controller
        defaultValue=""
        name="login"
        control={control}
        rules={{ required: true }}
        render={({ field }) => <ControlInput size={42} {...field} error={errors.login ? 'Введите логин' : ''} />}
      />

      <label className={styles.labelPassword}>Пароль</label>
      <Controller
        defaultValue=""
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <ControlInput size={42} {...field} type="password" error={errors.password ? 'Введите пароль' : ''} />
        )}
      />

      <div className={styles.errorMessage}>{responseError?.message}</div>
      <Button size={46} type="submit" className={styles.loginButton}>
        Войти
      </Button>
    </form>
  )
}

export default AuthForm
