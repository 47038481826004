import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { CheckboxProps } from './Checkbox.types'

import styles from './Checkbox.module.scss'

const Checkbox: React.FC<CheckboxProps> = ({ name, label, disabled, checked, onChange, ...restProps }) => {
  const cx = useStyles(styles)
  const checkbox = cx('container', {
    checked: checked,
  })

  return (
    <label data-testid="Checkbox" className={checkbox}>
      {label && label}
      <input name={name} type="checkbox" onChange={onChange} checked={checked} disabled={disabled} {...restProps} />
      <span className={styles.checkmark} />
    </label>
  )
}

export default Checkbox
