import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from 'providers/Auth/AuthProvider'
import Routes from 'routes'

import ErrorBoundary from 'components/containers/ErrorBoundary'

import 'styles/colors.scss'
import 'styles/fonts.scss'
import 'styles/global.scss'

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default App
