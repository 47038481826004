import React from 'react'
import { useStyles } from 'hooks/useStyles'
import TextArea from 'components/elements/TextArea'
import { ControlTextAreaProps } from './ControlTextArea.types'

import styles from 'components/containers/ControlInput/ControlInput.module.scss'

const ControlTextArea = React.forwardRef<HTMLTextAreaElement, ControlTextAreaProps>((props, ref) => {
  const { error, className, ...restProps } = props

  const cx = useStyles(styles)
  const classNames = cx(className, 'container')

  return (
    <div data-testid="ControlTextArea" className={classNames}>
      <TextArea invalid={!!error} {...restProps} />
      <div className={styles.errorMessage}>{error}</div>
    </div>
  )
})

ControlTextArea.defaultProps = {
  rows: 10,
}

export default ControlTextArea
