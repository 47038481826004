import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { MenuProps } from './Menu.types'

import styles from './Menu.module.scss'

const Menu = React.forwardRef<HTMLDivElement, MenuProps>(({ className, children, ...restProps }, ref) => {
  const cx = useStyles(styles)
  const classNames = cx(className, 'container')
  const count = !!React.Children.count(children)

  return (
    <>
      {count && (
        <div data-testid="Menu" ref={ref} className={classNames} {...restProps}>
          {children}
        </div>
      )}
    </>
  )
})

export default Menu
