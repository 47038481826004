import React from 'react'
import { useNavigate } from 'react-router-dom'
import { namedRoute, routes } from 'routes/routes'
import { MirrorApi, SiteApi } from 'api'

import Table from 'components/containers/Table'
import Paginator from 'components/containers/Table/Paginator'
import Modal from 'components/containers/Modal'
import Confirm from 'components/containers/Modal/modalContent/Confirm'
import AddSiteForm from 'components/forms/InlineForm'
import PopupMenu from 'components/containers/PopupMenu/index'
import Notification from 'components/elements/Notification'
import MirrorModalContent from './mirrorModalContent'
import Breadcrumbs from 'components/containers/Breadcrumbs'

import { TableColumn } from 'components/containers/Table/Table.types'
import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { InlineFormData } from 'types/inlineFormData'
import { SiteData } from 'types/siteData'

import styles from './sites.module.scss'

const tableConfig: TableColumn<SiteData>[] = [
  { title: 'ID', name: 'id', width: 85 },
  { title: 'Сайт', name: 'name', width: 400, actionOptionsIndex: 1 },
  { title: 'Число рекламных мест', name: 'places_count', align: 'right' },
]

const options = [
  { label: 'Зеркала', value: 'mirrors' },
  { label: 'Рекламные места', value: 'edit' },
  { label: 'Удалить', value: 'delete', warning: true },
]

const Sites: React.FC = () => {
  const [sitesList, setSitesList] = React.useState(null)
  const [error, setError] = React.useState<string>('')
  const [responseError, setResponseError] = React.useState<string>('')
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [pagination, setPagination] = React.useState({} as PaginationData)
  const [selectedSiteId, setSelectedSiteId] = React.useState<number>()
  const [action, setAction] = React.useState<string>()

  const navigate = useNavigate()

  // TODO: вероятно, стоит <Notification> прикрутить к Layout с контекст провайдером
  const getSites = async (payload = {}) => {
    const { response, error } = await SiteApi.list(payload)

    if (response) {
      setSitesList(response.items)
      setPagination(response.paginator)
    }

    error && setError(error.message)
  }

  const addSite = async (payload = {}) => {
    const { response, error } = await MirrorApi.add(payload)
    setResponseError('')

    if (response) {
      getSites()

      return response
    }

    error && setResponseError(error.message)
  }

  const deleteSite = async (id: number) => {
    const { response, error } = await SiteApi.delete(id)

    response && getSites()
    error && setError(error.message)
  }

  const handleCloseModal = () => setIsOpenModal(false)

  const handleAdd = (data: InlineFormData) => {
    return addSite({ url: data.field })
  }

  const handleDelete = () => {
    setIsOpenModal(false)
    selectedSiteId && deleteSite(selectedSiteId)
  }

  const modalContent = React.useMemo(() => {
    if (action === 'delete') {
      return <Confirm text={'Удалить сайт?'} handleCloseModal={handleCloseModal} handleDelete={handleDelete} />
    }

    return selectedSiteId && <MirrorModalContent siteId={selectedSiteId} setError={setError} />
  }, [action, selectedSiteId])

  const handlePopupAction = (value: string | number, id: number) => {
    if (value === 'edit') {
      navigate(namedRoute(routes.sites.edit.path, { siteId: id }))
      return
    }
    setIsOpenModal(true)
    setSelectedSiteId(id)
    setAction(String(value))
  }

  React.useEffect(() => {
    getSites()
  }, [])

  return (
    <>
      <Breadcrumbs route={routes.sites.index} routeGroup={routes.sites} setError={setError} />
      <AddSiteForm
        onSubmit={handleAdd}
        textButton={'Добавить сайт'}
        placeholder={'Введите URL'}
        tooltipText={'Имя сайта должно начинаться с http'}
        error={responseError}
      />
      <Table
        tConfig={tableConfig}
        data={sitesList}
        className={styles.sitesTable}
        emptyMessage={'Не добавлено ни одного сайта'}
      >
        <PopupMenu options={options} onChange={handlePopupAction} />
      </Table>
      <Paginator pagination={pagination} paginationFn={getSites} />

      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        {modalContent}
      </Modal>
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Sites
