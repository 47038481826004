import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { TabGroupProps, TabProps } from './Tabs.types'

import styles from './Tabs.module.scss'

const Tab: React.FC<TabProps> = ({ disable, className, ...restProps }) => {
  return <div data-testid="Tab" className={className} {...restProps} />
}

const TabGroup: React.FC<TabGroupProps> = ({ defaultTabIndex, children, className, ...restProps }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(defaultTabIndex)
  const cx = useStyles(styles)

  const container = cx('container', className)

  const clickHandler = React.useCallback(
    (e: React.MouseEvent, disable: boolean) => {
      if (disable) return
      const tabIndex = Number(e.currentTarget.getAttribute('data-tabindex'))
      setActiveTabIndex(tabIndex)
    },
    [children]
  )

  const navigation = React.useMemo(() => {
    return React.Children.map(children as React.ReactElement, (child, index) => {
      const tabHead = cx('tabHead', {
        activeTabHead: index === activeTabIndex,
      })
      const title = cx('title', {
        disabledTitle: child.props?.disable,
      })

      return (
        <div className={tabHead} data-tabindex={index} onClick={e => clickHandler(e, child.props.disable)}>
          <span className={title}>{child.props?.title}</span>
        </div>
      )
    })
  }, [children, activeTabIndex])

  // TODO: надо возвращать только активную вкладку (без map)
  const content = React.useMemo(() => {
    return React.Children.map(children as React.ReactElement, (child, index) => {
      const tabContent = cx('tabContent', {
        activeTabContent: index === activeTabIndex,
      })

      return <Tab className={tabContent}>{child}</Tab>
    })
  }, [children, activeTabIndex])

  React.useEffect(() => {
    setActiveTabIndex(defaultTabIndex || 0)
  }, [defaultTabIndex])

  return (
    <div data-testid="TabGroup" className={container} {...restProps}>
      <div className={styles.navigation}>{navigation}</div>
      <div className={styles.tabContainer}>{content}</div>
    </div>
  )
}

TabGroup.defaultProps = {
  defaultTabIndex: 0,
}

export { TabGroup, Tab }
