import * as React from 'react'
import { Routes as ReactRouters, Route, Navigate, useLocation } from 'react-router-dom'

import Layout from 'components/containers/Layout'
import { routes } from './routes'

import { useAuth } from 'hooks/useAuth'

import Login from 'pages/login'
import Users from 'pages/users'
import Sites from 'pages/sites'
import SitePlaces from 'pages/sites/sitePlaces'
import Places from 'pages/places'
import AddPlace from 'pages/places/add'
import EditPlace from 'pages/places/edit'
import Campaigns from 'pages/campaigns'
import ChangeCampaigns from 'pages/campaigns/change'
import Banners from 'pages/banners'
import AddBanner from 'pages/banners/add'
import EditBanner from 'pages/banners/edit'
import Targeting from 'pages/targeting'
import ChangeTargeting from 'pages/targeting/change'
import Statistic from 'pages/statistic'
import NotFound from 'pages/notFound'

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const { auth } = useAuth()
  const location = useLocation()

  return auth ? children : <Navigate to="/login" state={{ from: location }} replace />
}

const Routes = () => {
  return (
    <ReactRouters>
      <Route path={routes.home} element={<Login />} />
      <Route path={routes.login} element={<Login />} />
      <Route
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path={routes.users} element={<Users />} />
        <Route path={routes.sites.index.path} element={<Sites />} />
        <Route path={routes.sites.edit.path} element={<SitePlaces />} />
        <Route path={routes.places.index.path} element={<Places />} />
        <Route path={routes.places.add.path} element={<AddPlace />} />
        <Route path={routes.places.addToSite.path} element={<AddPlace />} />
        <Route path={routes.places.edit.path} element={<EditPlace />} />
        <Route path={routes.campaigns.index.path} element={<Campaigns />} />
        <Route path={routes.campaigns.edit.path} element={<ChangeCampaigns />} />
        <Route path={routes.campaigns.add.path} element={<ChangeCampaigns />} />
        <Route path={routes.banners.index.path} element={<Banners />} />
        <Route path={routes.banners.add.path} element={<AddBanner />} />
        <Route path={routes.banners.addToCampaign.path} element={<AddBanner />} />
        <Route path={routes.banners.edit.path} element={<EditBanner />} />
        <Route path={routes.targeting.index.path} element={<Targeting />} />
        <Route path={routes.targeting.add.path} element={<ChangeTargeting />} />
        <Route path={routes.targeting.edit.path} element={<ChangeTargeting />} />
        <Route path={routes.statistic.index.path} element={<Statistic />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </ReactRouters>
  )
}

export default Routes
