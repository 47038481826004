import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { namedRoute, routes } from 'routes/routes'
import { TargetingApi } from 'api'

import Table from 'components/containers/Table'
import Paginator from 'components/containers/Table/Paginator'
import Modal from 'components/containers/Modal'
import Confirm from 'components/containers/Modal/modalContent/Confirm'
import PopupMenu from 'components/containers/PopupMenu/index'
import Button from 'components/elements/Button'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import Notification from 'components/elements/Notification'

import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { TableColumn } from 'components/containers/Table/Table.types'
import { TargetingData } from 'types/targetingData'

import styles from './targeting.module.scss'

const tableConfig: TableColumn<TargetingData>[] = [
  { title: 'ID', name: 'id', width: 85 },
  { title: 'Название', name: 'name', width: 400, actionOptionsIndex: 0 },
]

const options = [
  { label: 'Редактировать', value: 'edit' },
  { label: 'Удалить', value: 'delete', warning: true },
]

const Targeting: React.FC = () => {
  const [targetingList, setTargetingList] = React.useState(null)
  const [pagination, setPagination] = React.useState<PaginationData>({} as PaginationData)
  const [selectedTargetingId, setSelectedTargetingId] = React.useState<number>()
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const navigate = useNavigate()

  // TODO: вынести в утилиту для переиспользования
  const [searchParams] = useSearchParams()
  const pagination_page = Number(searchParams.get('page'))
  const pagination_per_page = Number(searchParams.get('per'))

  const getTargetingList = async (params = { pagination_page, pagination_per_page }) => {
    const { response, error } = await TargetingApi.list(params)

    if (response) {
      setTargetingList(response.items)
      setPagination(response.paginator)
    }

    if (error) {
      setError(error.message)
    }
  }

  const deleteTargeting = async (id: number) => {
    const { response, error } = await TargetingApi.delete(id)

    if (response) {
      getTargetingList()
      handleCloseModal()
    }

    if (error) {
      setError(error.message)
    }
  }

  const handleCloseModal = () => setIsOpenModal(false)

  const handleDelete = React.useCallback(() => {
    selectedTargetingId && deleteTargeting(selectedTargetingId)
  }, [selectedTargetingId])

  const handleAction = (value: string | number, targetingId: number) => {
    if (value === 'edit') {
      navigate(namedRoute(routes.targeting.edit.path, { targetingId }))
      return
    }

    setSelectedTargetingId(targetingId)
    setIsOpenModal(true)
  }

  React.useEffect(() => {
    getTargetingList()
  }, [])

  return (
    <>
      <Breadcrumbs route={routes.targeting.index} routeGroup={routes.targeting} setError={setError} />
      <Button className={styles.button} onClick={() => navigate(routes.targeting.add.path)}>
        {'Добавить'}
      </Button>

      <Table tConfig={tableConfig} data={targetingList} emptyMessage={'Не добавлено ни одного таргетинга'}>
        <PopupMenu options={options} onChange={handleAction} />
      </Table>
      <Paginator pagination={pagination} paginationFn={getTargetingList} />

      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        <Confirm text={'Удалить таргетинг?'} handleCloseModal={handleCloseModal} handleDelete={handleDelete} />
      </Modal>
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Targeting
