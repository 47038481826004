import React from 'react'
import { StoreContext } from 'providers/Store/StoreProvider'

/**
 * React hook to work along with statistic config context provider
 *
 * @param none
 * @returns react context
 */

export function useStore() {
  return React.useContext(StoreContext)
}
