import { request } from 'api/request'

export default class SiteApi {
  static async list<T extends object>(params: T) {
    return request.get(`rest/site/`, { ...params })
  }

  static async get(id: number) {
    return request.get(`rest/site/${id}`, {})
  }

  static async delete(id: number) {
    return request.delete(`rest/site/${id}`, {})
  }
}
