import React from 'react'
import { usePopper } from 'react-popper'
import { useStyles } from 'hooks/useStyles'
import { QuestionIcon } from 'static/icons'

import style from './Tooltip.module.scss'

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {}

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { className, children } = props
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(null)

  const cx = useStyles(style)
  const tooltip = cx(className, 'container')

  const skidding = popperElement ? -popperElement.offsetWidth * 0.1 : null

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      { name: 'flip', options: { fallbackPlacements: ['top'] } },
      { name: 'offset', options: { offset: [skidding, 15] } },
      { name: 'arrow', options: { element: arrowElement, padding: 20 } },
      { name: 'computeStyles', options: { gpuAcceleration: false } },
    ],
  })

  return (
    <div ref={ref} data-testid="Tooltip" className={tooltip}>
      <div ref={setReferenceElement} className={style.questionIcon}>
        <QuestionIcon />
      </div>
      <div className={style.tooltip} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
        {children}
        <div ref={setArrowElement} style={styles.arrow} className={style.arrow} />
      </div>
    </div>
  )
})

export default Tooltip
