import React from 'react'
import { usePopper, Modifier } from 'react-popper'
import { useStyles } from 'hooks/useStyles'
import { useOutsideClick } from 'hooks/useOutsideClick'

import Dropdown from 'components/containers/Dropdown'
import { CrossIcon } from 'static/icons'

import { SelectOptions } from 'types/selectOptions'
import { AutocompleteProps } from './Autocomplete.types'

import style from './Autocomplete.module.scss'

const customModifier: Modifier<'sameWidth', any> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    const reference = state.elements.reference as HTMLElement
    state.elements.popper.style.width = `${reference.offsetWidth}px`
  },
}

const makeOptions = (value?: Array<string | number>, withOptions?: boolean) => {
  if (withOptions || !value) return {}

  const newOptionsMap = value?.reduce<Record<string, string>>((acc, item) => {
    //@ts-ignore
    acc[item] = item

    return acc
  }, {})

  return newOptionsMap || {}
}

const Autocomplete = React.forwardRef<HTMLInputElement, AutocompleteProps>((props, _ref) => {
  const {
    options,
    value,
    className,
    invalid,
    disabled,
    placeholder,
    withOptions,
    onChange,
    onSearch,
    onBlur,
    ...restProps
  } = props

  const inputRef = React.useRef(null)
  const [innerValue, setInnerValue] = React.useState(value)
  const [innerOptions, setInnerOptions] = React.useState(options)
  const [optionsMap, setOptionsMap] = React.useState<Record<SelectOptions['value'], string>>(
    makeOptions(value, withOptions)
  )
  const [searchText, setSearchText] = React.useState<string>()
  const [focused, setFocused] = React.useState(false)
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(containerRef, popperElement, {
    placement: 'bottom-start',
    modifiers: [customModifier],
  })

  const cx = useStyles(style)
  const autocomplete = cx(className, 'container', {
    focused,
    withOptions: !withOptions,
    disabled,
    invalid,
  })

  useOutsideClick(
    containerRef,
    () => {
      setInnerOptions(options)
      setFocused(false)
    },
    () => setFocused(focused => !focused)
  )

  const handleRemove = (removableValue: string | number) => {
    const filteredInnerValue = innerValue && innerValue.filter(item => item !== removableValue)

    const value = filteredInnerValue?.length ? filteredInnerValue : undefined
    setInnerValue(value)
    onChange && onChange(value)
  }

  const handleSelect = (selectedValue: string | number) => {
    if (innerValue && innerValue.find(existValue => existValue === selectedValue)) return

    setFocused(false)
    const value = innerValue ? [...innerValue, selectedValue] : [selectedValue]
    setInnerValue(value)
    onChange && onChange(value)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value.toLowerCase()
    setSearchText(e.target.value)
    if (onSearch) return

    const foundedOptions = options?.filter(option => option.label.toLocaleLowerCase().startsWith(searchString))
    setInnerOptions(foundedOptions)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      const value = (e.target as HTMLInputElement).value.toLowerCase()
      if ((optionsMap && optionsMap[value]) || withOptions || !value) return

      const newValue = innerValue ? [...innerValue, value] : [value]
      setInnerValue(newValue)
      setOptionsMap(optionsMap ? { ...optionsMap, [value]: value } : { [value]: value })

      if (inputRef.current) {
        ;(inputRef.current as HTMLInputElement).value = ''
      }

      onChange && onChange(newValue)
    }
  }

  React.useEffect(() => {
    onSearch && searchText && onSearch(searchText)
  }, [searchText])

  React.useEffect(() => {
    const newOptionsMap = options?.reduce<Record<SelectOptions['value'], string>>((acc, option) => {
      acc[option.value] = option.label

      return acc
    }, {})

    setOptionsMap({ ...optionsMap, ...newOptionsMap })

    options && setInnerOptions(options)
  }, [options])

  React.useEffect(() => {
    !focused && onBlur && onBlur()
  }, [focused])

  React.useEffect(() => {
    setInnerValue(value)
  }, [value])

  return (
    <div data-testid="Autocomplete" ref={setContainerRef} className={autocomplete}>
      <div className={style.values}>
        {!innerValue && !focused && <span className={style.placeholder}>{placeholder}</span>}
        {optionsMap &&
          innerValue &&
          innerValue.map(value => (
            <div key={value} className={style.label}>
              {optionsMap[value]}
              <CrossIcon className={style.removeIcon} onClick={() => handleRemove(value)} />
            </div>
          ))}
        {focused && (
          <input
            ref={inputRef}
            autoFocus
            className={style.input}
            disabled={disabled}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            {...restProps}
          />
        )}
      </div>

      {withOptions && focused && innerOptions && !disabled && (
        <Dropdown
          setPopperRef={setPopperElement}
          popperAttributes={attributes}
          options={innerOptions}
          styles={styles}
          onSelect={handleSelect}
        />
      )}
    </div>
  )
})

Autocomplete.defaultProps = {
  withOptions: true,
}

export default Autocomplete
