import React from 'react'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { CrossIcon } from 'static/icons'

import { ModalProps } from './Modal.types'

import styles from './Modal.module.scss'

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const [contentRef, setContentRef] = React.useState<HTMLElement | null>(null)

  useOutsideClick(contentRef, onClose)

  return (
    <>
      {isOpen && (
        <div data-testid="Modal" className={styles.backdrop}>
          {children && (
            <div className={styles.content} ref={setContentRef}>
              <span className={styles.close} onClick={onClose}>
                <CrossIcon />
              </span>
              {children}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Modal
