import { request } from 'api/request'

export default class PlaceApi {
  static async add<T>(data: T) {
    return request.post(`rest/site/place/`, data)
  }

  static async delete(id: number) {
    return request.delete(`rest/site/place/${id}`, {})
  }

  static async list<T extends object>(params: T) {
    return request.get(`rest/site/place/`, { ...params })
  }

  static async get(id: number) {
    return request.get(`rest/site/place/${id}`, {})
  }

  static async update<T>(id: number, data: T) {
    return request.put(`rest/site/place/${id}`, data)
  }
}
