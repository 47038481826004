import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { TagProps } from './Tag.types'

import styles from './Tag.module.scss'

const Tag: React.FC<TagProps> = ({ value, className, children, ...restProps }) => {
  const cx = useStyles(styles)

  const tag = cx(className, 'container', {
    [`state-${value}`]: value,
  })

  return (
    <div data-testid="Tag" className={tag} {...restProps}>
      {children}
    </div>
  )
}

export default Tag
