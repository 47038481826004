import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { routes } from 'routes/routes'
import SessionApi from 'api/session'
import { readCookie, setCookie } from 'lib/cookies'
import { useStyles } from 'hooks/useStyles'
import { useUserData } from 'hooks/useUserData'
import { useAuth } from 'hooks/useAuth'
import NavMenu from 'components/containers/NavMenu'
import MenuItem from 'components/containers/MenuItem'
import { ExitIcon, LogoIcon } from 'static/icons'
import { SidebarProps } from './Sidebar.types'

import styles from './Sidebar.module.scss'

const Sidebar: React.FC<SidebarProps> = ({ className, children, ...restProps }) => {
  const { userData } = useUserData()
  const { setAuth } = useAuth()
  const navigate = useNavigate()

  const cx = useStyles(styles)
  const sidebar = cx(className, 'sidebar')

  const logout = async () => {
    const token = readCookie('token')

    if (token) {
      const { response } = await SessionApi.logout(token)

      if (response) {
        setCookie('token', '')
        setAuth(false)
      }
    }
  }

  return (
    <aside data-testid="Sidebar" className={sidebar} {...restProps}>
      <div className={styles.header}>
        <Link className={styles.container} to={routes.home}>
          <LogoIcon className={styles.logo} />
          <h1 className={styles.title}>Krut.io</h1>
        </Link>
        <span className={styles.profile}>{userData?.email}</span>
      </div>
      <NavMenu />
      <MenuItem className={styles.logout} text={'Выход'} icon={<ExitIcon />} onClick={logout} />
    </aside>
  )
}

Sidebar.defaultProps = {}

export default Sidebar
