import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { dateWithTimeFormat } from 'lib/dates'

import Button from 'components/elements/Button'
import ControlInput from 'components/containers/ControlInput'
import ControlSelect from 'components/containers/ControllSelect'
import RadioButton from 'components/elements/RadioButton'
import DateInput from 'components/containers/DateInput'

import { rotationType } from 'constant/rotationType'
import { stateOptions } from 'constant/stateOptions'

import { CampaignFormProps } from './CampaignsForm.types'
import { CampaignData, Rotations } from 'types/campaignData'
import { DateRange } from 'components/containers/DateInput/DateInput.types'

import styles from './CampaignsForm.module.scss'

/** Combines interfaces CampaignData and DateRange to represent dates with api required format */
interface CampaignFormData extends CampaignData {
  rangeFrom?: Date
  rangeTo?: Date
}

const CampaignsForm: React.FC<CampaignFormProps> = ({ campaign: campaignData, targetingOptions, onSubmit }) => {
  const [campaignRotationType, setCampaignRotationType] = React.useState(campaignData?.rotation_type || 'random')

  const navigate = useNavigate()
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const submitInterceptor = (data: CampaignFormData) => {
    const { rangeFrom, rangeTo, enabled_date_time_from, enabled_date_time_to, ...rest } = data

    onSubmit({
      ...rest,
      ...(rangeFrom && { enabled_date_time_from: format(rangeFrom, dateWithTimeFormat) }),
      ...(rangeTo && { enabled_date_time_to: format(rangeTo, dateWithTimeFormat) }),
    })
  }

  React.useEffect(() => {
    campaignRotationType && setValue('rotation_type', campaignRotationType)
  }, [campaignRotationType])

  React.useEffect(() => {
    if (campaignData) {
      reset(campaignData)
      setCampaignRotationType(campaignData.rotation_type)

      const { enabled_date_time_from: from, enabled_date_time_to: to } = campaignData
      from && setValue('rangeFrom', parseISO(from))
      to && setValue('rangeTo', parseISO(to))
    }
  }, [campaignData])

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(submitInterceptor)} className={styles.form}>
        <label className={styles.label}>{'Название рекламной кампании'}</label>
        <Controller
          defaultValue={campaignData ? campaignData?.name : ''}
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ControlInput placeholder="Введите название" error={errors.name ? 'Обязательное поле' : ''} {...field} />
          )}
        />

        <label className={styles.label}>{'Активность кампании'}</label>
        <Controller
          defaultValue={''}
          name="rangeFrom"
          control={control}
          render={({ field }) => (
            <DateInput
              placeholder="Дата запуска"
              dateFormat="dd/MM/yyyy, p"
              placement="right-start"
              className={styles.dateFrom}
              {...field}
            />
          )}
        />
        <Controller
          defaultValue={''}
          name="rangeTo"
          control={control}
          render={({ field }) => (
            <DateInput placeholder="Дата остановки" dateFormat="dd/MM/yyyy, p" placement="right-start" {...field} />
          )}
        />

        <label className={styles.label}>{'Приоритет показа баннеров'}</label>
        <div className={styles.rotationTypeContainer}>
          {rotationType.map(type => (
            <RadioButton
              key={type.name}
              value={type.name}
              label={type.label}
              checked={campaignRotationType === type.name}
              onChange={() => setCampaignRotationType(type.name as Rotations)}
            />
          ))}
        </div>

        <label className={styles.label}>{'Таргетинг'}</label>
        <Controller
          defaultValue={campaignData?.targeting_id}
          name="targeting_id"
          control={control}
          render={({ field }) => (
            <ControlSelect clearable placeholder="Выбрать таргетинг" options={targetingOptions} {...field} />
          )}
        />
        {campaignData && (
          <>
            <label className={styles.label}>{'Состояние'}</label>
            <Controller
              defaultValue={campaignData?.state}
              name="state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <ControlSelect options={stateOptions} {...field} />}
            />
          </>
        )}
        <div className={styles.buttons}>
          <Button type="button" onClick={() => navigate(-1)} variant="secondary">
            {'Отмена'}
          </Button>
          <Button type="submit">{campaignData ? 'Сохранить' : 'Создать'}</Button>
        </div>
      </form>
    </div>
  )
}

export default CampaignsForm
