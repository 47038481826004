import { request } from 'api/request'
import { AuthData } from 'types/authData'

export default class SessionApi {
  static async login(data: AuthData) {
    return request.post('rest/session/', data)
  }

  static async get(token: string) {
    return request.get(`rest/session/${token}`, {})
  }

  static async logout(token: string) {
    return request.delete(`rest/session/${token}`, {})
  }
}
