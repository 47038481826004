import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { CampaignApi, PlaceApi } from 'api'
import { makeOptions } from 'lib/utils'
import { SelectOptions } from 'types/selectOptions'
import PlacesForm from 'components/forms/PlacesForm'
import Notification from 'components/elements/Notification'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import { PlaceData } from 'types/placeData'

import styles from '../places.module.scss'

const EditPlace: React.FC = () => {
  const [place, setPlace] = React.useState<PlaceData | undefined>()
  const [campaignOptions, setCampaignOptions] = React.useState<SelectOptions[]>([])
  const [error, setError] = React.useState<string>('')

  const { placeId } = useParams()
  const navigate = useNavigate()

  const getPlaces = async (id: number) => {
    const { response, error } = await PlaceApi.get(id)

    response && setPlace(response)
    error && setError(error.message)
  }

  const getCampaigns = async () => {
    const { response, error } = await CampaignApi.list({ pagination_per_page: 999 })

    response && setCampaignOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const submitHandler = async (data: PlaceData) => {
    if (!place) return

    const payload = data.campaign_id ? data : { name: data.name }
    const { response, error } = await PlaceApi.update(place.id, { ...payload, ...place.format })

    response && navigate(-1)
    error && setError(error.message)
  }

  React.useEffect(() => {
    getPlaces(Number(placeId))
    getCampaigns()
  }, [])

  return (
    <div>
      <Breadcrumbs route={routes.places.edit} routeGroup={routes.places} params={{ placeId }} setError={setError} />
      {!!place && (
        <PlacesForm
          place={place}
          campaignId={place.campaign_id}
          campaignOptions={campaignOptions}
          onSubmit={submitHandler}
        />
      )}
      <Notification setError={setError}>{error}</Notification>
    </div>
  )
}

export default EditPlace
