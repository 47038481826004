import { request } from 'api/request'

export default class MirrorApi {
  static async add<T extends object>(payload: T) {
    return request.post(`rest/site/mirror/`, { ...payload })
  }

  static async delete(id: number) {
    return request.delete(`rest/site/mirror/${id}`, {})
  }

  static async list(site_id: number) {
    return request.get(`rest/site/mirror/`, { site_id })
  }
}
