import React from 'react'
import { usePopper } from 'react-popper'

import Portal from 'components/elements/Portal'

import { ViewerProps } from './Viewer.types'

import style from './Viewer.module.scss'

const Viewer = React.forwardRef<HTMLDivElement, ViewerProps>((props, _ref) => {
  const { visible = true, triggerElement, height } = props
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 20,
        },
      },
    ],
  })

  return visible && height ? (
    <Portal>
      <div ref={setPopperElement} style={styles.popper} className={style.container} {...attributes.popper}>
        <iframe title="preview" id="preview" className={style.iframe} height={height} />
      </div>
    </Portal>
  ) : null
})

export default Viewer
