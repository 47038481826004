import React from 'react'
import MenuItem from 'components/containers/MenuItem'
import Menu from 'components/containers/Menu'
import Portal from 'components/elements/Portal'
import { DropdownProps } from './Dropdown.types'

import { useStyles } from 'hooks/useStyles'
import style from './Dropdown.module.scss'

const Dropdown: React.FC<DropdownProps> = ({
  setPopperRef,
  popperAttributes,
  options,
  styles,
  selectedValue,
  onSelect,
}) => {
  const cx = useStyles(style)

  return (
    <Portal>
      <Menu className={style.dropdown} ref={setPopperRef} style={styles.popper} {...popperAttributes.popper}>
        {options?.map(({ label, value, warning }) => {
          if (Array.isArray(selectedValue) && selectedValue?.includes(value)) {
            return
          }
          const menuItem = cx('dropdownItem', {
            warningItem: warning,
            activeItem: value === selectedValue,
          })

          return <MenuItem onClick={() => onSelect(value)} className={menuItem} key={value} text={label} />
        })}
      </Menu>
    </Portal>
  )
}

export default Dropdown
