import { request } from 'api/request'

export default class BannerApi {
  static async add<T>(payload: T) {
    return request.post(`rest/banner/`, payload)
  }

  static async list<T>(params: T) {
    return request.get('rest/banner/', { ...params })
  }

  static async get(id: number) {
    return request.get(`rest/banner/${id}`, {})
  }

  static async update<T>(id: number, payload: T) {
    return request.put(`rest/banner/${id}`, payload)
  }

  static async delete(id: number) {
    return request.delete(`rest/banner/${id}`, {})
  }
}
