import { SelectOptions } from 'types/selectOptions'

/** Function makes options list from initial response data and returns it, useful for Select component
 *
 * @param initialData - initial data for options list
 * @param labelField - name of field in the initialData with label
 * @param valueField - name of field in the initialData with value
 * @returns - options list
 */
export const makeOptions = <T extends {}>(initialData: Array<T>, labelField: keyof T, valueField: keyof T) => {
  if (
    !Array.isArray(initialData) ||
    !initialData.length ||
    !initialData[0].hasOwnProperty(labelField) ||
    !initialData[0].hasOwnProperty(valueField)
  ) {
    return []
  }

  return initialData.map(
    entity => ({ label: entity[labelField], value: entity[valueField] } as unknown as SelectOptions)
  )
}

/** Function converts array to map, e.g. useful for checkbox group
 *
 * @param array - input array
 * @returns - map
 */
export const arrayToMap = <T extends string>(array: T[]) => {
  const map = new Map()
  array.forEach(item => map.set(item, true))

  return map
}

/** Function converts map to array, e.g. useful for checkbox group
 *
 * @param map
 * @returns
 */
export const mapToArray = <T extends string>(map: Map<T, boolean>) => {
  return Array.from(map, ([key]) => key)
}
