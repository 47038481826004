import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { TargetingApi, AdmApi } from 'api'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import TargetingForm from 'components/forms/TargetingForm'
import Notification from 'components/elements/Notification'
import { makeOptions } from 'lib/utils'
import { GeoOptions, TargetingData } from 'types/targetingData'
import { geoTargeting } from 'constant/targeting'

import styles from '../targeting.module.scss'

const makeGeoOptions = (data: any) => {
  return data
    ? {
        countries: makeOptions(data.countries, 'name', 'id'),
        regions: makeOptions(data.regions, 'name', 'id'),
        cities: makeOptions(data.cities, 'name', 'id'),
      }
    : undefined
}

const ChangeTargeting: React.FC = () => {
  const [targetingData, setTargetingData] = React.useState<TargetingData>()
  const [defaultGeoOptions, setDefaultGeoOptions] = React.useState<GeoOptions>()
  const [geoOptions, setGeoOptions] = React.useState<GeoOptions>()
  const [error, setError] = React.useState<string>('')

  const navigate = useNavigate()
  const { targetingId } = useParams()

  const getTargetingData = async () => {
    const { response, error } = await TargetingApi.get(Number(targetingId))

    response &&
      setTargetingData({
        ...response,
        config_keywords: JSON.parse(response.config_keywords)?.keywords,
        config_os: JSON.parse(response.config_os),
        config_geo: JSON.parse(response.config_geo),
        config_load_limit: JSON.parse(response.config_load_limit),
        config_day_and_hour: JSON.parse(response.config_day_and_hour),
      })
    error && setError(error.message)
  }

  const getDefaultGeo = async () => {
    const { response, error } = await AdmApi.config()

    response && setDefaultGeoOptions(makeGeoOptions(response.default_geo))
    error && setError(error.message)
  }

  const getGeo = async <T extends {}>(params: T) => {
    const { response, error } = await AdmApi.suggestGeo(params)

    response && setGeoOptions(makeGeoOptions(response))
    error && setError(error.message)
  }

  const getGeoNames = async <T extends {}>(params: T) => {
    const { response, error } = await AdmApi.fillGeo(params)

    response && setGeoOptions(makeGeoOptions(response))
    error && setError(error.message)
  }

  const handleSubmit = async ({
    name,
    config_keywords,
    config_os,
    config_load_limit,
    config_day_and_hour,
    ...rest
  }: TargetingData) => {
    const { countries = null, regions = null, cities = null, cities_exclude = null } = { ...rest }
    const withGeo = countries || regions || cities || cities_exclude
    const payload = {
      name,
      //TODO: в request.ts добавить удаление всех полей со значением undefined
      ...(config_keywords?.length && { config_keywords: JSON.stringify({ keywords: config_keywords, type: 'any' }) }),
      ...(config_os.length && { config_os: JSON.stringify(config_os) }),
      ...(config_load_limit && { config_load_limit: JSON.stringify({ load_limit: Number(config_load_limit) }) }),
      ...(config_day_and_hour && { config_day_and_hour: JSON.stringify(config_day_and_hour) }),
      //@ts-ignore
      ...(withGeo && { config_geo: JSON.stringify({ countries, regions, cities, cities_exclude }) }),
    }

    const { response, error } = targetingId
      ? await TargetingApi.update(Number(targetingId), payload)
      : await TargetingApi.add(payload)

    response && navigate(-1)
    error && setError(error.message)
  }

  const handleGeoQuery = ({ query, types, countriesIds }: { query: string; types: string; countriesIds: number[] }) => {
    if (query.length < 2) return

    const params = { query, types, ...(countriesIds && { countries_ids: countriesIds?.join(',') }) }

    getGeo(params)
  }

  const handleResetOptions = () => {
    setGeoOptions(makeGeoOptions(undefined))
  }

  React.useEffect(() => {
    if (!targetingData?.config_geo) return

    const modifiedGeoTargetingData = { ...targetingData.config_geo }
    const { cities, cities_exclude } = modifiedGeoTargetingData
    const modifiedCities = [...(cities || []), ...(cities_exclude || [])]
    modifiedGeoTargetingData.cities = modifiedCities.length ? modifiedCities : null
    modifiedGeoTargetingData.cities_exclude = null

    const query = geoTargeting.reduce((acc, type) => {
      //@ts-ignore
      if (modifiedGeoTargetingData[type]) {
        //@ts-ignore
        acc = { ...acc, [`${type}_ids`]: modifiedGeoTargetingData[type].join(',') }
      }

      return acc
    }, {})

    getGeoNames(query)
  }, [targetingData])

  React.useEffect(() => {
    targetingId && getTargetingData()
    getDefaultGeo()
  }, [targetingId])

  return (
    <>
      <Breadcrumbs
        params={{ targetingId }}
        route={targetingId ? routes.targeting.edit : routes.targeting.add}
        routeGroup={routes.targeting}
        setError={setError}
      />
      {(!targetingId || targetingData) && (
        <TargetingForm
          targetingData={targetingData}
          defaultGeoOptions={defaultGeoOptions}
          geoOptions={geoOptions}
          onGeoQuery={handleGeoQuery}
          onSubmit={handleSubmit}
          onChange={handleResetOptions}
        />
      )}
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default ChangeTargeting
