import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes, namedRoute } from 'routes/routes'
import { PlaceApi } from 'api'
import { useStore } from 'hooks/useStore'
import { sortDirectionOptions } from 'constant/sortOptions'

import CopyModalContent from '../copyModalContent/copyModalContent'
import Table from 'components/containers/Table'
import PopupMenu from 'components/containers/PopupMenu/index'
import Modal from 'components/containers/Modal'
import Confirm from 'components/containers/Modal/modalContent/Confirm'
import QuickSelectButton from 'components/containers/QuickSelectButton'

import { PlaceTableProps } from './PlaceTable.types'
import { TableColumn } from 'components/containers/Table/Table.types'
import { PlaceData } from 'types/placeData'
import { SortDirection } from 'types/sort'

import styles from '../places.module.scss'

const tableConfig: TableColumn<PlaceData>[] = [
  { title: 'ID', name: 'id', width: 85 },
  { title: 'Название', name: 'name', width: 400, actionOptionsIndex: 1 },
]

const options = [
  { label: 'Скопировать код', value: 'copy' },
  { label: 'Редактировать', value: 'edit' },
  { label: 'Удалить', value: 'delete', warning: true },
]

const PlaceTable: React.FC<PlaceTableProps> = ({ getPlaces, setError, placeList }) => {
  const navigate = useNavigate()
  const { sort } = useStore()
  const { places: sortDirection, setSortSettings } = sort

  const [selectedPlaceId, setSelectedPlaceId] = React.useState<number>()
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [action, setAction] = React.useState<string>()

  const deletePlace = async (id: number) => {
    const { response, error } = await PlaceApi.delete(id)

    response && getPlaces()
    error && setError(error.message)
  }

  const handleCloseModal = () => setIsOpenModal(false)

  const handleDelete = () => {
    setIsOpenModal(false)
    selectedPlaceId && deletePlace(selectedPlaceId)
  }

  const modalContent = React.useMemo(() => {
    if (action === 'delete') {
      return <Confirm text={'Удалить место?'} handleCloseModal={handleCloseModal} handleDelete={handleDelete} />
    }

    return selectedPlaceId && <CopyModalContent placeId={selectedPlaceId} setError={setError} />
  }, [action, selectedPlaceId])

  const handleAction = (value: string | number, placeId: number) => {
    setAction(String(value))
    switch (value) {
      case 'copy':
        setIsOpenModal(true)
        setSelectedPlaceId(placeId)
        break
      case 'edit':
        navigate(namedRoute(routes.places.edit.path, { placeId }))
        break
      case 'delete':
        setIsOpenModal(true)
        setSelectedPlaceId(placeId)
        break
    }
  }

  return (
    <>
      <div className={styles.sortDirectionContainer}>
        <span>{'Показать:'}</span>
        <QuickSelectButton
          className={styles.sortDirection}
          options={sortDirectionOptions}
          activeButtonValue={sortDirection}
          onSelect={direction => setSortSettings({ ...sort, places: direction as SortDirection })}
        />
      </div>
      <Table tConfig={tableConfig} data={placeList} emptyMessage={'Не добавлено ни одного рекламного места'}>
        <PopupMenu options={options} onChange={handleAction} />
      </Table>
      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        {modalContent}
      </Modal>
    </>
  )
}

export default PlaceTable
