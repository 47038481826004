import { cookieOptions } from 'types/cookie'

function readCookie(cookieName: string): string | null {
  let name = cookieName + '=',
    cookieArray = document.cookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    let temp = cookieArray[i].trim()

    if (temp.indexOf(name) === 0) {
      return temp.substring(name.length, temp.length)
    }
  }
  return null
}

function setCookie(name: string, value: string, options: cookieOptions = { path: '/' }): void {
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey

    let optionValue = options[optionKey as keyof typeof options]
    if (!!optionValue !== true) {
      updatedCookie += '=' + optionValue
    }
  }
  document.cookie = updatedCookie
}

export { readCookie, setCookie }
