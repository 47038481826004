import axios, { AxiosInstance } from 'axios'
import { readCookie } from 'lib/cookies'
import { ResponseType } from 'types/request'

const options = { baseURL: process.env.REACT_APP_API }

//TODO  нужно будет посмотреть нужна ли эта абстракция над методами аксиоса (при подключении React-query)
class ApiClient {
  private client: AxiosInstance

  constructor() {
    this.client = axios.create(options)
    this.client.interceptors.request.use(function (config) {
      const token = readCookie('token')
      if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`
      }

      return config
    })
  }

  errorHandler(e: any) {
    let error
    if (e.response) {
      error = e.response.data.error
    } else {
      error = { message: 'Неизвестная ошибка' }
    }

    return error
  }

  async post<TRequest>(path: string, payload: TRequest): Promise<ResponseType> {
    let response,
      error = null
    try {
      const { data } = await this.client.post(path, payload)
      response = data.data
    } catch (e: any) {
      error = this.errorHandler(e)
    }

    return { response, error }
  }

  async get<TRequest>(path: string, payload: TRequest): Promise<ResponseType> {
    let response,
      error = null
    try {
      const { data } = await this.client.get(path, { params: payload })
      response = data.data
    } catch (e: any) {
      error = this.errorHandler(e)
    }

    return { response, error }
  }

  async patch<TRequest, TResponse>(path: string, payload: TRequest) {
    let response,
      error = null
    try {
      const { data } = await this.client.patch(path, payload)
      response = data.data
    } catch (e: any) {
      error = this.errorHandler(e)
    }

    return { response, error }
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest) {
    let response,
      error = null
    try {
      const { data } = await this.client.put(path, payload)
      response = data.is_success
    } catch (e: any) {
      error = this.errorHandler(e)
    }

    return { response, error }
  }

  async delete<TRequest, TResponse>(path: string, payload: TRequest) {
    let response,
      error = null
    try {
      const { data } = await this.client.delete(path, payload)
      response = data.is_success
    } catch (e: any) {
      error = this.errorHandler(e)
    }

    return { response, error }
  }
}

const ApiInstance = new ApiClient()

export { ApiInstance as request }
