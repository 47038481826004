import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes, namedRoute } from 'routes/routes'
import { CampaignApi } from 'api'
import { useStore } from 'hooks/useStore'
import { tagValue } from 'constant/tagValue'
import { sortDirectionOptions } from 'constant/sortOptions'

import Table from 'components/containers/Table/'
import Paginator from 'components/containers/Table/Paginator'
import Button from 'components/elements/Button'
import PopupMenu from 'components/containers/PopupMenu/index'
import Modal from 'components/containers/Modal'
import Notification from 'components/elements/Notification'
import Confirm from 'components/containers/Modal/modalContent/Confirm'
import Tag from 'components/elements/Tag'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import QuickSelectButton from 'components/containers/QuickSelectButton'

import { TableColumn } from 'components/containers/Table/Table.types'
import { TagValue } from 'types/tag'
import { CampaignData } from 'types/campaignData'
import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { SortDirection } from 'types/sort'

import styles from './campaigns.module.scss'

const tableConfig: TableColumn<CampaignData>[] = [
  { title: 'ID', name: 'id', width: 85 },
  { title: 'Название', name: 'name', width: 400, actionOptionsIndex: 1 },
  { title: 'Статус', name: 'state', render: (value: TagValue) => <Tag value={value}>{tagValue[value]}</Tag> },
]

const Campaigns: React.FC = () => {
  const navigate = useNavigate()
  const { sort } = useStore()
  const { campaigns: sortDirection, setSortSettings } = sort

  const [selectedCampaign, setSelectedCampaign] = React.useState<CampaignData>()
  const [campaignList, setCampaignList] = React.useState<CampaignData[] | null>(null)
  const [pagination, setPagination] = React.useState({} as PaginationData)
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<number>()
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const [searchParams] = useSearchParams()
  const pagination_page = Number(searchParams.get('page'))
  const pagination_per_page = Number(searchParams.get('per'))

  const getCampaigns = async (params = { pagination_page, pagination_per_page }) => {
    const { response, error } = await CampaignApi.list({ ...params, sort: `id_${sortDirection}` })

    if (response) {
      setCampaignList(response.items)
      setPagination(response.paginator)
    }
    error && setError(error.message)
  }

  const getCampaign = async (id: number): Promise<CampaignData | undefined> => {
    const { response, error } = await CampaignApi.get(id)

    if (response) {
      return response
    }
    error && setError(error.message)
  }

  const updateCampaignState = async (id: number, payload: CampaignData) => {
    const { response, error } = await CampaignApi.update(id, payload)

    response && getCampaigns()
    error && setError(error.message)
  }

  const deleteCampaign = async (id: number) => {
    const { response, error } = await CampaignApi.delete(id)

    if (response) {
      getCampaigns()
      handleCloseModal()
    }
    error && setError(error.message)
  }

  const handleCloseModal = () => setIsOpenModal(false)

  const handleDelete = React.useCallback(() => {
    selectedCampaignId && deleteCampaign(selectedCampaignId)
  }, [selectedCampaignId])

  const handleChangeState = async (id: number) => {
    const data = await getCampaign(id)
    if (data) {
      const { targeting_id, state: prevState, rotation_type, name, enabled_date_time_from, enabled_date_time_to } = data
      const state = prevState === 'started' ? 'paused' : 'started'
      const payload = {
        ...(targeting_id && { targeting_id }),
        state,
        rotation_type,
        name,
        enabled_date_time_from,
        enabled_date_time_to,
      }

      updateCampaignState(id, payload as CampaignData)
    }
  }

  const handleAction = (value: string | number, campaignId: number) => {
    switch (value) {
      case 'state':
        handleChangeState(campaignId)
        break
      case 'edit':
        navigate(namedRoute(routes.campaigns.edit.path, { campaignId }))
        break
      case 'delete':
        setSelectedCampaignId(campaignId)
        setIsOpenModal(true)
        break
    }
  }

  const options = React.useMemo(
    () => [
      { label: selectedCampaign?.state === 'started' ? 'Приостановить' : 'Запустить', value: 'state' },
      { label: 'Редактировать', value: 'edit' },
      { label: 'Удалить', value: 'delete', warning: true },
    ],
    [selectedCampaign]
  )

  const modalContent = React.useMemo(
    () => <Confirm text={'Удалить кампанию?'} handleCloseModal={handleCloseModal} handleDelete={handleDelete} />,
    [handleDelete]
  )

  React.useEffect(() => {
    getCampaigns()
  }, [sortDirection])

  return (
    <>
      <Breadcrumbs route={routes.campaigns.index} routeGroup={routes.campaigns} setError={setError} />
      <Button onClick={() => navigate(routes.campaigns.add.path)}>{'Добавить'}</Button>
      <div className={styles.sortDirectionContainer}>
        <span>{'Показать:'}</span>
        <QuickSelectButton
          className={styles.sortDirection}
          options={sortDirectionOptions}
          activeButtonValue={sortDirection}
          onSelect={direction => setSortSettings({ ...sort, campaigns: direction as SortDirection })}
        />
      </div>
      <Table tConfig={tableConfig} data={campaignList} emptyMessage={'Не добавлено ни одной рекламной компании'}>
        <PopupMenu
          options={options}
          onChange={handleAction}
          onOpen={(campaign: CampaignData) => setSelectedCampaign(campaign)}
        />
      </Table>
      <Paginator pagination={pagination} paginationFn={getCampaigns} />

      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        {modalContent}
      </Modal>
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Campaigns
