import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes/routes'
import { AttachmentApi, BannerApi, CampaignApi, FormatApi, TargetingApi } from 'api'
import { makeOptions } from 'lib/utils'
import BannersForm from 'components/forms/BannersForm'
import Notification from 'components/elements/Notification'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import { FormatBanner } from './addBanner.types'
import { BannerData } from 'types/bannerData'
import { SelectOptions } from 'types/selectOptions'

import styles from '../banners.module.scss'

const AddBanner: React.FC = () => {
  const [targetingOptions, setTargetingOptions] = React.useState<SelectOptions[]>([])
  const [campaignOptions, setCampaignOptions] = React.useState<SelectOptions[]>([])
  const [formatOptions, setFormatOptions] = React.useState<SelectOptions[]>([])
  const [imageId, setImageId] = React.useState<string>()
  const [error, setError] = React.useState<string>('')
  const navigate = useNavigate()

  const getTargetingList = async () => {
    const { response, error } = await TargetingApi.list({ pagination_per_page: 999 })

    response && setTargetingOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const getCampaignList = async () => {
    const { response, error } = await CampaignApi.list({ pagination_per_page: 999 })

    response && setCampaignOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const getFormatList = async () => {
    const { response, error } = await FormatApi.get({ pagination_per_page: 999 })

    if (response) {
      const formatList = response.items.reduce((acc: Array<unknown>, item: FormatBanner) => {
        acc.push({ format: `${item.width || '100%'} x ${item.height || '100%'}`, formatId: item.id })
        return acc
      }, [])

      setFormatOptions(makeOptions(formatList, 'format', 'formatId'))
    }

    error && setError(error.message)
  }

  const handleSubmit = async (data: BannerData) => {
    const { type, code, image, click_url, ...rest } = data
    const payload = code ? { type: 'html', code, ...rest } : { type: 'image', code: imageId, click_url, ...rest }
    const { response, error } = await BannerApi.add(payload)

    response && navigate(-1)
    error && setError(error.message)
  }

  const handleImageSelect = async (file: File) => {
    const payload = new FormData()
    payload.append('image', file)
    const { response, error } = await AttachmentApi.imageUpload(payload)

    response && setImageId(String(response.id))
    error && setError(error.message)
  }

  React.useEffect(() => {
    getCampaignList()
    getTargetingList()
    getFormatList()
  }, [])

  return (
    <>
      <Breadcrumbs route={routes.banners.add} routeGroup={routes.banners} setError={setError} />
      <BannersForm
        campaignOptions={campaignOptions}
        targetingOptions={targetingOptions}
        formatOptions={formatOptions}
        onSubmit={handleSubmit}
        onImageSelect={handleImageSelect}
      />
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default AddBanner
