import React from 'react'
import { AdmApi, PlaceApi, SiteApi } from 'api'

import Button from 'components/elements/Button'

import { CopyModalContentProps } from './copyModalContent.types'
import { PlaceData } from 'types/placeData'

import styles from './copyModalContent.module.scss'

const rmCodeEmbed = (placeKey: string, placeName: string) => {
  return `<!-- ${placeName} -->\n<div data-rm="${placeKey}"></div>`
}

const scriptCodeEmbed = (host: string, siteKey: string) => {
  return `<script type="text/javascript" src="//${host}/${siteKey}.js"></script>`
}

const CopyModalContent: React.FC<CopyModalContentProps> = ({ placeId, setError }) => {
  const [placeData, setPlaceData] = React.useState<PlaceData>()
  const [siteData, setSiteData] = React.useState<PlaceData>()
  const [rotatorHost, setRotatorHost] = React.useState<string>()

  const getPlace = async (id: number) => {
    const { response, error } = await PlaceApi.get(id)
    const { response: responseConf, error: errorConf } = await AdmApi.config()

    if (response) {
      setPlaceData(response)
    }

    responseConf && setRotatorHost(responseConf.rotator_host)

    if (error || errorConf) {
      setError(error.message || errorConf.message)
    }
  }

  const getSite = async (id: number) => {
    const { response, error } = await SiteApi.get(id)

    if (response) {
      setSiteData(response)
    }

    if (error) {
      setError(error.message)
    }
  }

  const rmCodeCopyHandler = () => {
    placeData && navigator.clipboard.writeText(rmCodeEmbed(placeData.key, placeData.name))
  }

  const scriptCodeCopyHandler = () => {
    rotatorHost && siteData && navigator.clipboard.writeText(scriptCodeEmbed(rotatorHost, siteData.key))
  }

  React.useEffect(() => {
    getPlace(placeId)
  }, [placeId])

  React.useEffect(() => {
    placeData && getSite(placeData.site_id)
  }, [placeData])

  return (
    <>
      <p className={styles.label}>{'Вставляется в рекламном месте'}</p>
      <div className={styles.block}>
        <textarea
          className={styles.textarea}
          rows={3}
          defaultValue={placeData && rmCodeEmbed(placeData.key, placeData.name)}
        />
        <Button onClick={rmCodeCopyHandler}>{'Скопировать'}</Button>
      </div>
      <p className={styles.label}>{`Вставляется после <body>`}</p>
      <div className={styles.block}>
        <textarea
          className={styles.textarea}
          rows={3}
          defaultValue={rotatorHost && siteData && scriptCodeEmbed(rotatorHost, siteData.key)}
        />
        <Button onClick={scriptCodeCopyHandler}>{'Скопировать'}</Button>
      </div>
    </>
  )
}

export default CopyModalContent
