import React from 'react'
import Button from 'components/elements/Button'
import { Link } from 'react-router-dom'
import { routes } from 'routes/routes'

import styles from './NotFound.module.scss'

const NotFound: React.FC = () => {
  return (
    <section className={styles.notFound}>
      <div>
        <h1 className={styles.title}>404</h1>
        <p className={styles.subtitle}>Страница не найдена</p>
        <p className={styles.subtitle}>Лучше 404 с кнопкой "назад", чем журавль в небе</p>
        <Link className={styles.container} to={routes.home}>
          <Button>На главную</Button>
        </Link>
      </div>
    </section>
  )
}

export default NotFound
