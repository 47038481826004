import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { routes } from 'routes/routes'
import { BannerApi } from 'api'
import { useStore } from 'hooks/useStore'

import Paginator from 'components/containers/Table/Paginator'
import Button from 'components/elements/Button'
import Notification from 'components/elements/Notification'
import BannerTable from './table/BannerTable'
import Breadcrumbs from 'components/containers/Breadcrumbs'

import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { BannerData } from 'types/bannerData'

import styles from './banners.module.scss'

const Banners: React.FC = () => {
  const navigate = useNavigate()
  const {
    sort: { banners: sortDirection },
  } = useStore()

  const [bannerList, setBannerList] = React.useState<BannerData[] | null>(null)
  const [pagination, setPagination] = React.useState<PaginationData>({} as PaginationData)
  const [error, setError] = React.useState<string>('')

  const [searchParams] = useSearchParams()
  const pagination_page = Number(searchParams.get('page'))
  const pagination_per_page = Number(searchParams.get('per'))

  const getBanners = async (params = { pagination_page, pagination_per_page }) => {
    const { response, error } = await BannerApi.list({ ...params, sort: `id_${sortDirection}` })

    if (response) {
      setBannerList(response.items)
      setPagination(response.paginator)
    }

    error && setError(error.message)
  }

  React.useEffect(() => {
    getBanners()
  }, [sortDirection])

  return (
    <>
      <Breadcrumbs route={routes.banners.index} routeGroup={routes.banners} setError={setError} />
      <Button onClick={() => navigate(routes.banners.add.path)}>{'Добавить'}</Button>

      <BannerTable getBanners={getBanners} bannerList={bannerList} setError={setError} />
      <Paginator pagination={pagination} paginationFn={getBanners} />

      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default Banners
