import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useOutsideClick } from 'hooks/useOutsideClick'
import Loader from 'components/elements/Loader'
import TableRow from './TableRow'

import { RequiredAttributes } from 'types/general'
import { TableProps, ColumnAlign } from './Table.types'

import style from './Table.module.scss'

export const makeStyles = (position?: ColumnAlign, actionOptionsIndex: number | undefined = undefined) => ({
  [`align-${position}`]: position,
  'align-left': !position,
  clickableCell: actionOptionsIndex !== undefined,
})

const Table = <T extends RequiredAttributes>(props: TableProps<T>) => {
  const { data, tConfig, emptyMessage, withControl, className, children, ...restProps } = props

  const cx = useStyles(style)
  const container = cx(className, 'container')

  const [selectedRow, setSelectedRow] = React.useState<T>()
  const [isOpenPopup, setIsOpenPopup] = React.useState<boolean>(false)
  const [triggerElement, setTriggerElement] = React.useState<HTMLElement | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)

  let popupMenu

  if (withControl && React.Children.count(children) === 1) {
    const child = React.Children.only(children) as React.ReactElement

    popupMenu = React.cloneElement(child, { triggerElement, id: selectedRow?.id })
  }

  useOutsideClick(triggerElement, () => setIsOpenPopup(false))

  const handleClick = (e: React.MouseEvent, row: T) => {
    setTriggerElement(e.target as HTMLElement)
    setIsOpenPopup(true)
    setSelectedRow(row)
    React.isValidElement(children) && typeof children.props.onOpen === 'function' && children.props.onOpen(row)
  }

  const handleAction = (e: React.MouseEvent, id: number, actionOptionsIndex: number | undefined) => {
    e.stopPropagation()
    if (!React.isValidElement(children) || actionOptionsIndex === undefined) return

    const { options, onChange } = children.props
    const isNewTab = e.ctrlKey || e.metaKey
    onChange(options[actionOptionsIndex].value, id, isNewTab)
  }

  React.useEffect(() => {
    data && setIsLoading(false)
  }, [data])

  const ColumnsSpan: React.FC = ({ children }) => (
    <tr>
      <td className={style.emptyMessage} colSpan={withControl ? tConfig.length + 1 : tConfig.length}>
        {children}
      </td>
    </tr>
  )

  return (
    <div className={container} {...restProps}>
      <table data-testid="Table" className={style.table}>
        <thead>
          <tr>
            {tConfig.map((column, index) => (
              <th className={cx(makeStyles(column.align))} style={{ width: `${column.width}px` }} key={`th-${index}`}>
                {column.title}
              </th>
            ))}
            {withControl && <th className={style['align-right']}>Управление</th>}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <ColumnsSpan children={<Loader />} />
          ) : data && data.length ? (
            data.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow
                  tConfig={tConfig}
                  row={row}
                  controlRef={setTriggerElement}
                  withControl={withControl}
                  handleClick={handleClick}
                  handleAction={handleAction}
                />
                {index === data.length - 1 && <tr key={'empty-tail-row'} />}
              </React.Fragment>
            ))
          ) : (
            <ColumnsSpan children={emptyMessage} />
          )}
        </tbody>
      </table>
      {isOpenPopup && popupMenu}
    </div>
  )
}

Table.defaultProps = {
  withControl: true,
}

export default Table
