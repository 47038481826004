import React from 'react'
import { useStyles } from 'hooks/useStyles'

import Button from 'components/elements/Button'

import { QuickSelectButtonProps } from './QuickSelectButton.types'

import styles from './QuickSelectButton.module.scss'

const QuickSelectButton = React.forwardRef<HTMLDivElement, QuickSelectButtonProps>((props, ref) => {
  const { options, disabled, activeButtonValue, onSelect, className } = props
  const cx = useStyles(styles)

  const quickSelectButton = cx(className, 'container')

  return (
    <div className={quickSelectButton} ref={ref}>
      {options.map(quickOption => (
        <Button
          active={activeButtonValue === quickOption.value}
          disabled={disabled}
          type="button"
          variant="text"
          onClick={() => onSelect(quickOption.value)}
          key={quickOption.value}
        >
          {quickOption.label}
        </Button>
      ))}
    </div>
  )
})

QuickSelectButton.defaultProps = {}

export default QuickSelectButton
