import React from 'react'
import { Link, Params } from 'react-router-dom'
import { namedRoute } from 'routes/routes'
import { useStyles } from 'hooks/useStyles'
import breadcrumbsApi from 'api/breadcrumb'
import { BreadcrumbsProps, BreadcrumbType } from './Breadcrumbs.types'

import styles from './Breadcrumbs.module.scss'

const toQueryParams = (params: Params) => {
  const queryParams = Object.keys(params).reduce((acc, param) => {
    const queryParam = param.replace('Id', '_id')
    //@ts-ignore
    acc[queryParam] = params[param]

    return acc
  }, {})

  return queryParams
}

const createLabel = (breadcrumbName: string, routeNames: any) => {
  const foundTemplates = breadcrumbName.match(/:[A-Za-z_]+/g)

  if (!foundTemplates) return breadcrumbName
  if (!routeNames) return ''

  const label: string = foundTemplates.reduce((acc, template) => {
    const id = template.replace(':', '')

    return acc + breadcrumbName.replace(template, routeNames[id])
  }, '')

  return label
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ route, routeGroup, params, setError }) => {
  const [routNames, setRoutNames] = React.useState<Params>()

  const cx = useStyles(styles)

  const getRoutNames = async (params: Params) => {
    const query = toQueryParams(params)
    const { response, error } = await breadcrumbsApi.get(query)

    response && setRoutNames(response)
    error && setError(error.message)
  }

  const breadcrumbs: BreadcrumbType[] = React.useMemo(() => {
    const pathNames = route.breadcrumbPath.split('/') as Array<keyof typeof routeGroup>
    const crumbs = pathNames.map((pathname: keyof typeof routeGroup) => {
      const label = createLabel(routeGroup[pathname].breadcrumbName, routNames)
      const to = namedRoute(routeGroup[pathname].path, params)

      return { label, to }
    })

    return crumbs
  }, [routNames])

  React.useEffect(() => {
    params && getRoutNames(params)
  }, [])

  return (
    <div className={styles.container}>
      {breadcrumbs.map(({ label, to }, index: number) => (
        <Link to={to} key={label} className={cx('title', { lastCrumb: index === breadcrumbs.length - 1 })}>
          {label}
          {index < breadcrumbs.length - 1 && ' /'}
          &nbsp;
        </Link>
      ))}
    </div>
  )
}

export default Breadcrumbs
