import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { RadioButtonProps } from './RadioButton.types'

import styles from './RadioButton.module.scss'

const RadioButton: React.FC<RadioButtonProps> = ({ label, value, checked, className, ...restProps }) => {
  const cx = useStyles(styles)

  const radioButton = cx(className, 'container')
  const radio = cx('radioButton', {
    checked,
  })

  return (
    <label data-testid="RadioButton" className={radioButton}>
      {label && <span className={styles.label}>{label}</span>}
      <input type="radio" value={value} checked={checked} {...restProps} />
      <span className={radio} />
      {!label && <span className={styles.value}>{value}</span>}
    </label>
  )
}

export default RadioButton
