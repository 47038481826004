import React from 'react'
import Button from 'components/elements/Button'
import { ConfirmProps } from './Confirm.types'

import styles from './Confirm.module.scss'

const Confirm: React.FC<ConfirmProps> = ({ text, handleCloseModal, handleDelete }) => {
  return (
    <>
      {text && <div className={styles.modalTitle}>{text}</div>}
      <div className={styles.modalBtnBlock}>
        <Button variant="secondary" onClick={handleCloseModal}>
          {'Отмена'}
        </Button>
        <Button onClick={handleDelete}>{'Удалить'}</Button>
      </div>
    </>
  )
}

export default Confirm
