import React from 'react'
import { Outlet } from 'react-router-dom'
import { UserDataProvider } from 'providers/UserData/UserDataProvider'
import { StoreProvider } from 'providers/Store/StoreProvider'
import Sidebar from 'components/containers/Sidebar'

import styles from './Layout.module.scss'

const Layout = () => {
  return (
    <div className={styles.layoutContainer}>
      <UserDataProvider>
        <Sidebar />
      </UserDataProvider>

      <div className={styles.pageContainer}>
        <StoreProvider>
          <Outlet />
        </StoreProvider>
      </div>
    </div>
  )
}

export default Layout
