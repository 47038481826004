import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes, namedRoute } from 'routes/routes'
import { AttachmentApi, BannerApi } from 'api'
import { addPreview } from 'lib/preview'
import { useStore } from 'hooks/useStore'
import { IS_NEW_WINDOW } from 'constant/common'
import { sortDirectionOptions } from 'constant/sortOptions'
import { tagValue } from 'constant/tagValue'

import Table from 'components/containers/Table'
import Modal from 'components/containers/Modal'
import Confirm from 'components/containers/Modal/modalContent/Confirm'
import PopupMenu from 'components/containers/PopupMenu'
import QuickSelectButton from 'components/containers/QuickSelectButton'
import Tag from 'components/elements/Tag'
import Loader from 'components/elements/Loader'

import { BannerData } from 'types/bannerData'
import { TagValue } from 'types/tag'
import { BannerTableProps } from './BannerTable.types'
import { TableColumn } from 'components/containers/Table/Table.types'
import { SortDirection } from 'types/sort'

import styles from '../banners.module.scss'

const tableConfig: TableColumn<BannerData>[] = [
  { title: 'ID', name: 'id', width: 85 },
  { title: 'Название', name: 'name', width: 400, actionOptionsIndex: 2 },
  { title: 'Статус', name: 'state', render: (value: TagValue) => <Tag value={value}>{tagValue[value]}</Tag> },
]

const BannerTable: React.FC<BannerTableProps> = ({ getBanners, setError, bannerList }) => {
  const { sort } = useStore()
  const { banners: sortDirection, setSortSettings } = sort

  const [selectedBannerImageUrl, setSelectedBannerImageUrl] = React.useState<string>()
  const [selectedBanner, setSelectedBanner] = React.useState<BannerData>()
  const [selectedBannerId, setSelectedBannerId] = React.useState<number>()
  const [selectedBannerData, setSelectedBannerData] = React.useState<BannerData | undefined>()
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [loadingData, setLoadingData] = React.useState<boolean>(false)
  const [action, setAction] = React.useState<string>()

  const navigate = useNavigate()

  const getImage = async (id: string) => {
    const { response, error } = await AttachmentApi.imageGet(id)

    response && setSelectedBannerImageUrl(response.items[0].url)
    error && setError(error.message)
  }

  const getBanner = async (id: number) => {
    const { response, error } = await BannerApi.get(id)

    if (response) {
      setSelectedBannerData(response)
      response.type === 'image' && getImage(response.code)

      return response
    }

    error && setError(error.message)
  }

  const updateBannerState = async <T extends {}>(id: number, payload: T) => {
    const { response, error } = await BannerApi.update(id, payload)

    response && getBanners()
    error && setError(error.message)
  }

  const deleteBanner = async (id: number) => {
    const { response, error } = await BannerApi.delete(id)

    if (response) {
      getBanners()
      handleCloseModal()
    }

    error && setError(error.message)
  }

  const handleChangeState = async (id: number) => {
    const data = await getBanner(id)
    if (data) {
      const newState = data.state === 'started' ? 'paused' : 'started'
      updateBannerState(id, {
        name: data.name,
        code: data.code,
        state: newState,
        ...(data.targeting_id && { targeting_id: data.targeting_id }),
        ...(data.click_url && { click_url: data.click_url }),
      })
    }
  }

  const handleCloseModal = React.useCallback(() => {
    setIsOpenModal(false)
  }, [setIsOpenModal])

  const handleDelete = React.useCallback(() => {
    selectedBannerId && deleteBanner(selectedBannerId)
  }, [selectedBannerId])

  const handleAction = (value: string | number, bannerId: number, newTab?: boolean) => {
    setAction(String(value))
    switch (value) {
      case 'preview':
        setLoadingData(true)
        setIsOpenModal(true)
        getBanner(bannerId).then(() => setLoadingData(false))
        break
      case 'state':
        handleChangeState(bannerId)
        break
      case 'edit':
        const route = namedRoute(routes.banners.edit.path, { bannerId })
        newTab ? window.open(route, IS_NEW_WINDOW) : navigate(route)
        break
      case 'delete':
        setSelectedBannerId(bannerId)
        setIsOpenModal(true)
        break
    }
  }

  const options = React.useMemo(
    () => [
      { label: 'Предпросмотр', value: 'preview' },
      { label: selectedBanner?.state === 'started' ? 'Приостановить' : 'Запустить', value: 'state' },
      { label: 'Редактировать', value: 'edit' },
      { label: 'Удалить', value: 'delete', warning: true },
    ],
    [selectedBanner]
  )

  const modalContent = React.useMemo(() => {
    if (action === 'preview' && selectedBannerData) {
      return <iframe id="preview" className={styles.preview} />
    }

    return <Confirm text={'Удалить баннер?'} handleCloseModal={handleCloseModal} handleDelete={handleDelete} />
  }, [action, selectedBannerData, selectedBannerId])

  React.useEffect(() => {
    if (action === 'preview' && isOpenModal && selectedBannerData && !loadingData) {
      addPreview(selectedBannerData, selectedBannerImageUrl)
    }
  }, [action, isOpenModal, selectedBannerData, loadingData, selectedBannerImageUrl])

  return (
    <>
      <div className={styles.sortDirectionContainer}>
        <span>{'Показать:'}</span>
        <QuickSelectButton
          className={styles.sortDirection}
          options={sortDirectionOptions}
          activeButtonValue={sortDirection}
          onSelect={direction => setSortSettings({ ...sort, banners: direction as SortDirection })}
        />
      </div>
      <Table tConfig={tableConfig} data={bannerList} emptyMessage={'Не добавлено ни одного баннера'}>
        <PopupMenu
          options={options}
          onChange={handleAction}
          onOpen={(banner: BannerData) => setSelectedBanner(banner)}
        />
      </Table>

      <Modal isOpen={isOpenModal} onClose={handleCloseModal}>
        {loadingData ? <Loader /> : modalContent}
      </Modal>
    </>
  )
}

export default BannerTable
