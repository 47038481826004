import { routes } from 'routes/routes'
import { UsersIcon, SitesIcon, PlacesIcon, CampaignIcon, BannersIcon, TargetIcon, StatisticIcon } from 'static/icons'

/** Navigation menu in the sidebar */
export const navMenuItems = [
  {
    text: 'Пользователи',
    icon: <UsersIcon />,
    to: routes.users,
    disabled: true,
  },
  {
    text: 'Сайты',
    icon: <SitesIcon />,
    to: routes.sites.index.path,
    disabled: false,
  },
  {
    text: 'Рекламные места',
    icon: <PlacesIcon />,
    to: routes.places.index.path,
    disabled: false,
  },
  {
    text: 'Рекламные кампании',
    icon: <CampaignIcon />,
    to: routes.campaigns.index.path,
    disabled: false,
  },
  {
    text: 'Баннеры',
    icon: <BannersIcon />,
    to: routes.banners.index.path,
    disabled: false,
  },
  {
    text: 'Таргет',
    icon: <TargetIcon />,
    to: routes.targeting.index.path,
    disabled: false,
  },
  {
    text: 'Статистика',
    icon: <StatisticIcon />,
    to: routes.statistic.index.path,
    disabled: false,
  },
]
