import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BannerApi, TargetingApi } from 'api'
import { routes } from 'routes/routes'
import Breadcrumbs from 'components/containers/Breadcrumbs'
import BannersForm from 'components/forms/BannersForm'
import Notification from 'components/elements/Notification'
import Loader from 'components/elements/Loader'
import { SelectOptions } from 'types/selectOptions'
import { BannerData } from 'types/bannerData'
import { makeOptions } from 'lib/utils'

import styles from '../banners.module.scss'

const EditBanner: React.FC = () => {
  const { bannerId } = useParams()
  const [bannerData, setBannerData] = React.useState<BannerData | undefined>()
  const [targetingOptions, setTargetingOptions] = React.useState<SelectOptions[]>([])
  const [error, setError] = React.useState<string>('')
  const navigate = useNavigate()

  const getBannerData = async () => {
    const { response, error } = await BannerApi.get(Number(bannerId))

    response && setBannerData(response)
    error && setError(error.message)
  }

  const getTargetingList = async () => {
    const { response, error } = await TargetingApi.list({ pagination_per_page: 999 })

    response && setTargetingOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const handleSubmit = async (data: BannerData) => {
    data.state = bannerData?.state
    const { code, format_id, click_url, ...rest } = data
    const payload = !click_url ? { code, ...rest } : { code: bannerData?.code, click_url, ...rest }
    const { response, error } = await BannerApi.update(Number(bannerId), payload)

    response && navigate(-1)
    error && setError(error.message)
  }

  React.useEffect(() => {
    getBannerData()
    getTargetingList()
  }, [])

  return (
    <div>
      <Breadcrumbs route={routes.banners.edit} routeGroup={routes.banners} params={{ bannerId }} setError={setError} />
      {bannerData ? (
        <BannersForm bannerData={bannerData} targetingOptions={targetingOptions} onSubmit={handleSubmit} />
      ) : (
        <Loader />
      )}
      <Notification setError={setError}>{error}</Notification>
    </div>
  )
}

export default EditBanner
