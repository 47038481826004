import React from 'react'
import { Controller } from 'react-hook-form'

import ControlInput from 'components/containers/ControlInput'
import ControlTextArea from 'components/containers/ControlTextArea/ControlTextArea'
import FileUploader from 'components/containers/FileUploader'

import { BannerTypeSwitcherProps } from './BannerTypeSwitcher.types'

import styles from '../BannersForm.module.scss'

const BannerTypeSwitcher: React.FC<BannerTypeSwitcherProps> = ({ control, errors, bannerData, bannerType }) => {
  return (
    <>
      {bannerType === 'html' ? (
        <>
          <label className={styles.label}>{'Код баннера'}</label>
          <Controller
            defaultValue={bannerData ? bannerData?.code : ''}
            name="code"
            control={control}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <ControlTextArea
                placeholder="Вставьте код"
                {...field}
                rows={8}
                className={styles.textarea}
                error={errors.code?.message}
              />
            )}
          />
        </>
      ) : (
        <>
          {!bannerData && (
            <>
              <label className={styles.label}>{'Загрузить картинку'}</label>
              <div className={styles.uploadFileWrapper}>
                <Controller
                  name="image"
                  rules={{ required: 'Обязательное поле' }}
                  control={control}
                  render={({ field }) => (
                    <FileUploader
                      {...field}
                      className={styles.fileUploader}
                      tooltipText={'Формат загружаемой картинки \n jpg или gif. Максимальный размер 5Мб.'}
                      error={errors.image?.message}
                    />
                  )}
                />
              </div>
            </>
          )}
          <label className={styles.label}>{'Ссылка для перехода'}</label>
          <Controller
            defaultValue={bannerData ? bannerData?.click_url : ''}
            name="click_url"
            control={control}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <ControlInput placeholder="Вставьте ссылку" {...field} error={errors.click_url?.message} />
            )}
          />
        </>
      )}
    </>
  )
}

export default BannerTypeSwitcher
