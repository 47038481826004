import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { SessionApi } from 'api'
import { setCookie } from 'lib/cookies'
import { useAuth } from 'hooks/useAuth'
import { routes } from 'routes/routes'
import AuthForm from 'components/forms/AuthForm'
import { LogoLargeIcon } from 'static/icons'
import { AuthData } from 'types/authData'

import styles from './login.module.scss'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { auth, setAuth } = useAuth()
  const [responseError, setResponseError] = React.useState({})

  const handlerSubmit = async (data: AuthData) => {
    setResponseError({})
    const { response, error } = await SessionApi.login({ ...data })

    if (response) {
      setAuth(response.user_id)
      setCookie('token', response.token)
      navigate(routes.sites.index.path, { replace: true })
    }

    error && setResponseError(error)
  }

  React.useEffect(() => {
    if (auth) {
      location.state
        ? navigate(location.state.from.pathname, { replace: true })
        : navigate(routes.sites.index.path, { replace: true })
    }
  }, [auth])

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.logoWrapper}>
          <p className={styles.title}>Вход</p>
          <LogoLargeIcon className={styles.logo} data-testid="LogoLargeIcon" />
        </div>

        <div className={styles.loginFormWrapper}>
          <AuthForm onSubmit={handlerSubmit} responseError={responseError} />
        </div>
      </div>
    </div>
  )
}

export default Login
