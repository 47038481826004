import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { MenuItemProps } from './MenuItem.types'

import styles from './MenuItem.module.scss'

const MenuItem: React.FC<MenuItemProps> = ({ text, icon, disabled, className, children, ...restProps }) => {
  const cx = useStyles(styles)

  const classNames = cx(className, 'container')
  const textStyle = cx(styles.text, { disabled })
  const iconStyle = cx(styles.icon, { disabled })

  return (
    <div data-testid="MenuItem" className={classNames} {...restProps}>
      {icon ? <span className={iconStyle}>{icon}</span> : null}
      <div className={styles.content}>
        <div className={textStyle}>{text}</div>
        {children}
      </div>
    </div>
  )
}

export default MenuItem
