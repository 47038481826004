import React from 'react'
import { useStyles } from 'hooks/useStyles'

import Tooltip from 'components/elements/Tooltip'
import Button from 'components/elements/Button'
import ControlInput from 'components/containers/ControlInput'

import { FileUploaderProps } from './FileUploader.types'

import styles from './FileUploader.module.scss'

const FileUploader = React.forwardRef<HTMLInputElement, FileUploaderProps>((props, _ref) => {
  const { buttonText, error, tooltipText, className, onChange } = props

  const fileUploaderRef = React.useRef<HTMLInputElement>(null)
  const [thumbnail, setThumbnail] = React.useState<string>()

  const cx = useStyles(styles)
  const container = cx(className, 'container')

  const handleClick = () => {
    fileUploaderRef.current && fileUploaderRef.current.click()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    e.target.files && onChange && onChange(e.target.files[0])
  }

  const handlePreviewOn = (e: React.MouseEvent<HTMLInputElement>) => {
    //@ts-ignore
    const file = e.target && e.target.files[0]
    if (file && file.type.includes('image')) {
      setThumbnail(URL.createObjectURL(file))
    }
  }

  const handlePreviewOff = () => {
    setThumbnail(undefined)
  }

  return (
    <div data-testid="FileUploader" className={container}>
      <ControlInput
        ref={fileUploaderRef}
        type="file"
        accept=".jpg, .jpeg, .gif"
        onChange={handleChange}
        error={error}
        onMouseEnter={handlePreviewOn}
        onMouseLeave={handlePreviewOff}
      />
      {tooltipText && <Tooltip>{tooltipText}</Tooltip>}
      <Button onClick={handleClick} type="button">
        {buttonText}
      </Button>
      {thumbnail && <img className={styles.thumbnail} src={thumbnail} alt="thumbnail" />}
    </div>
  )
})

FileUploader.defaultProps = {
  buttonText: 'Выберите файл',
}

export default FileUploader
