import { BannerData } from 'types/bannerData'

/** Function add iframe to DOM and writes content to it
 *
 * @param banner - banner data
 * @param imageUrl - image url (if type of banner is image)
 */
export const addPreview = (banner: BannerData, imageUrl?: string) => {
  const image = imageUrl
    ? `<img src='${imageUrl}'/>`
    : `<img src='/fallback.png'/ style='position:relative;top:calc(50% - 20px);left:calc(50% - 20px)'>`

  const content = banner?.type === 'html' ? banner.code : image

  const iframe = document.getElementById('preview') as HTMLIFrameElement

  if (iframe.contentDocument) {
    iframe.contentDocument.open()
    iframe.contentDocument.write(content as string)
    iframe.contentDocument.body.style.margin = '0'
    iframe.contentDocument.body.style.backgroundColor = '#FFF'

    iframe.width = String(banner.format.width)
    iframe.height = String(banner.format.height)
    iframe.contentDocument.close()
  }
}
