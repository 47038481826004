import React from 'react'
import { usePopper, Modifier } from 'react-popper'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { useStyles } from 'hooks/useStyles'

import { CrossIcon } from 'static/icons'

import ControlInput from 'components/containers/ControlInput'
import Dropdown from 'components/containers/Dropdown'

import { SelectOptions } from 'types/selectOptions'
import { ControlSelectProps } from './ControlSelect.types'

import style from './ControlSelect.module.scss'

// TODO: можно вынести popper в хук (здесь и в popupMenu)
const customModifier: Modifier<'sameWidth', any> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    const reference = state.elements.reference as HTMLElement
    state.elements.popper.style.width = `${reference.offsetWidth}px`
  },
}

const ControlSelect = React.forwardRef<HTMLInputElement, ControlSelectProps>((props, ref) => {
  const { options, value, disabled, className, onChange, clearable, ...restProps } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [innerValue, setInnerValue] = React.useState(value)
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(containerRef, popperElement, {
    placement: 'bottom-start',
    modifiers: [customModifier],
  })

  const cx = useStyles(style)
  const select = cx(className, 'container', {
    selectOpen: isOpen,
    clearable: clearable && value,
    disabled: disabled,
  })

  useOutsideClick(
    containerRef,
    () => setIsOpen(false),
    () => setIsOpen(isOpen => !isOpen)
  )

  const handleChange = (value: string | number | undefined) => {
    onChange && onChange(value)
    setInnerValue(value)
  }

  const handleClearValue = (e: React.MouseEvent) => {
    e.preventDefault()
    handleChange(undefined)
  }

  const displayValue = React.useMemo(() => {
    if (!innerValue) {
      return ''
    }
    const optionsList = options?.reduce<Record<SelectOptions['value'], string>>((acc, option) => {
      acc[option.value] = option.label

      return acc
    }, {})

    if (optionsList) {
      return optionsList[innerValue] || ''
    }
  }, [options, innerValue])

  React.useEffect(() => {
    setInnerValue(value)
  }, [value])

  return (
    <div data-testid="Select" ref={setContainerRef} className={select}>
      <ControlInput ref={ref} className={style.select} value={displayValue} readOnly {...restProps} />
      {isOpen && options && !disabled && (
        <Dropdown
          selectedValue={innerValue}
          setPopperRef={setPopperElement}
          popperAttributes={attributes}
          options={options}
          styles={styles}
          onSelect={handleChange}
        />
      )}
      {clearable && value && <CrossIcon className={style.crossIcon} onClick={handleClearValue} />}
    </div>
  )
})

export default ControlSelect
