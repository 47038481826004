import classNames, { Binding } from 'classnames/bind'

/**
 * React hook to working with css modules
 *
 * @param styles - css styles
 * @returns function
 */

export function useStyles(styles: Binding): typeof classNames {
  return classNames.bind(styles)
}
