import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { routes, namedRoute } from 'routes/routes'
import { PlaceApi } from 'api'
import { useStore } from 'hooks/useStore'
import PlaceTable from 'pages/places/table/PlaceTable'

import Paginator from 'components/containers/Table/Paginator'
import Notification from 'components/elements/Notification'
import Button from 'components/elements/Button'
import Breadcrumbs from 'components/containers/Breadcrumbs'

import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { PlaceData } from 'types/placeData'

import styles from '../sites.module.scss'

const SitePlaces: React.FC = () => {
  const { siteId } = useParams()
  const navigate = useNavigate()
  const {
    sort: { places: sortDirection },
  } = useStore()

  const [placeList, setPlaceList] = React.useState<PlaceData[] | null>(null)
  const [pagination, setPagination] = React.useState<PaginationData>()
  const [error, setError] = React.useState<string>('')

  const getPlaces = async (params = {}) => {
    const payload = { site_id: siteId, ...params }
    const { response, error } = await PlaceApi.list({ ...payload, sort: `id_${sortDirection}` })

    if (response) {
      setPlaceList(response.items)
      setPagination(response.paginator)
    }

    error && setError(error.message)
  }

  React.useEffect(() => {
    siteId && getPlaces()
  }, [siteId, sortDirection])

  return (
    <>
      <Breadcrumbs route={routes.sites.edit} routeGroup={routes.sites} params={{ siteId }} setError={setError} />
      <Button onClick={() => navigate(namedRoute(routes.places.addToSite.path, { siteId }))}>{'Добавить'}</Button>
      <PlaceTable placeList={placeList} getPlaces={getPlaces} setError={setError} />
      {pagination && <Paginator pagination={pagination} paginationFn={getPlaces} />}
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default SitePlaces
