import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useStore } from 'hooks/useStore'

import Button from 'components/elements/Button'

import { OptionButtonsProps } from './OptionButtons.types'

import styles from './OptionButtons.module.scss'

const OptionButtons = React.forwardRef<HTMLButtonElement, OptionButtonsProps>((props, ref) => {
  const { option, className, ...restProps } = props
  const cx = useStyles(styles)

  const {
    statistic: { config, setConfig },
  } = useStore()

  const optionButton = React.useMemo(
    () =>
      cx(className, {
        active: option.value === config?.sort_field,
      }),
    [className, config, option]
  )

  const handleClick = () => {
    config &&
      setConfig({
        ...config,
        sort_field: option.value,
      })
  }

  return (
    <Button ref={ref} variant="text" className={optionButton} onClick={handleClick} {...restProps}>
      {option.label}
    </Button>
  )
})

export default OptionButtons
