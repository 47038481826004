import React from 'react'
import { usePopper } from 'react-popper'
import Dropdown from 'components/containers/Dropdown'
import { PopupMenuProps } from './PopupMenu.types'

const PopupMenu: React.FC<PopupMenuProps> = ({ options, triggerElement, id, onChange }) => {
  const [innerValue, setInnerValue] = React.useState<string | number>()
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [5, -15],
        },
      },
    ],
  })

  const handleSelect = (value: string | number) => {
    setInnerValue(value)
  }

  React.useEffect(() => {
    onChange && innerValue && id && onChange(innerValue, id)
  }, [innerValue])

  return (
    <Dropdown
      setPopperRef={setPopperElement}
      popperAttributes={attributes}
      options={options}
      styles={styles}
      onSelect={handleSelect}
    />
  )
}

export default PopupMenu
