import React from 'react'
import { useStyles } from 'hooks/useStyles'

import OptionButtons from '../OptionButtons'
import DirectionArrows from '../DirectionArrows'

import { HeadProps } from './Head.types'

import styles from './Head.module.scss'

const Head = React.forwardRef<HTMLDivElement, HeadProps>((props, ref) => {
  const { options, withSortDirection = false, position } = props

  const cx = useStyles(styles)

  const container = cx('head', {
    [`align-${position}`]: position,
    'align-left': !position,
  })

  return (
    <div className={container} ref={ref}>
      <OptionButtons option={options} />
      {withSortDirection && <DirectionArrows group={options.value} />}
    </div>
  )
})

export default Head
