import React from 'react'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import Tooltip from 'components/elements/Tooltip'
import Button from 'components/elements/Button'
import ControlInput from 'components/containers/ControlInput'
import { InlineFormProps } from './InlineForm.types'
import { InlineFormData } from 'types/inlineFormData'

import styles from './InlineForm.module.scss'

const InlineForm: React.FC<InlineFormProps> = ({ textButton, placeholder, error, onSubmit, tooltipText }) => {
  const {
    control,
    clearErrors,
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<InlineFormData>({
    reValidateMode: 'onSubmit',
    defaultValues: { field: '' },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: FieldValues) => {
    clearErrors()
    field.onChange(e.target.value)
  }

  const submitForm = async (data: InlineFormData) => {
    const response = await onSubmit(data)
    response && reset()
  }

  React.useEffect(() => {
    error && setError('field', { type: 'api', message: error })
  }, [error])

  return (
    <form data-testid="InlineForm" onSubmit={handleSubmit(submitForm)} className={styles.form}>
      <div className={styles.container}>
        <Controller
          name="field"
          rules={{ required: 'Обязательное поле' }}
          control={control}
          render={({ field }) => (
            <ControlInput
              {...field}
              className={styles.input}
              placeholder={placeholder}
              error={Object.keys(errors).length ? errors.field?.message : ''}
              onChange={e => handleChange(e, field)}
            />
          )}
        />
        {tooltipText && <Tooltip>{tooltipText}</Tooltip>}
        <Button className={styles.button} type="submit">
          {textButton}
        </Button>
      </div>
    </form>
  )
}

export default InlineForm
