import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useStore } from 'hooks/useStore'
import { ArrowUpIcon } from 'static/icons'

import Button from 'components/elements/Button'

import { SortDirection } from 'types/sort'
import { DirectionArrowsProps } from './DirectionArrows.types'

import styles from './DirectionArrows.module.scss'

const DirectionArrows = React.forwardRef<HTMLDivElement, DirectionArrowsProps>((props, ref) => {
  const { group, className, ...restProps } = props

  const cx = useStyles(styles)
  const directionArrows = cx(className, 'container')

  const {
    statistic: { config, setConfig },
  } = useStore()

  const activeGroup = React.useMemo(() => {
    switch (config?.sort_field) {
      case 'views_all':
      case 'loads':
        return 'views_all'

      case 'ctr':
      case 'clicks':
        return 'ctr'

      default:
        return 'profit'
    }
  }, [config])

  const ascButton = React.useMemo(
    () =>
      cx({
        active: group === activeGroup && config?.sort_direction === 'asc',
      }),
    [activeGroup, config, group]
  )

  const descButton = React.useMemo(
    () =>
      cx({
        active: group === activeGroup && config?.sort_direction === 'desc',
      }),
    [activeGroup, config, group]
  )

  const handleClick = (direction: SortDirection) => {
    config &&
      setConfig({
        ...config,
        ...(activeGroup !== group && { sort_field: group }),
        sort_direction: direction,
      })
  }

  return (
    <div data-testid="DirectionArrows" className={directionArrows} ref={ref} {...restProps}>
      <Button className={ascButton} variant="icon" onClick={() => handleClick('asc')}>
        <ArrowUpIcon />
      </Button>
      <Button className={descButton} variant="icon" onClick={() => handleClick('desc')}>
        <ArrowUpIcon className={cx('arrowDown')} />
      </Button>
    </div>
  )
})

export default DirectionArrows
