import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { arrayToMap, mapToArray } from 'lib/utils'

import RadioButton from 'components/elements/RadioButton'

import { WeeklyTimePickerProps } from './WeeklyTimePicker.types'

import styles from './WeeklyTimePicker.module.scss'

const days = [
  { value: 'none', label: '' },
  { value: 'Monday', label: 'пн' },
  { value: 'Tuesday', label: 'вт' },
  { value: 'Wednesday', label: 'ср' },
  { value: 'Thursday', label: 'чт' },
  { value: 'Friday', label: 'пт' },
  { value: 'Saturday', label: 'сб' },
  { value: 'Sunday', label: 'вс' },
]
const times = Array.from(Array(24).keys())

const makeInnerValue = (value: any) => {
  let innerValue: any = {}
  for (let day in value) {
    innerValue[day] = arrayToMap(value[day])
  }

  return innerValue
}

const makeValue = (innerValue: any) => {
  let value: any = {}
  for (let day in innerValue) {
    const times = mapToArray(innerValue[day])
    if (times.length) {
      value[day] = times
    }
  }

  return value
}

const WeeklyTimePicker = React.forwardRef<HTMLInputElement, WeeklyTimePickerProps>((props, _ref) => {
  const { value = {}, className, onChange } = props

  const [innerValue, setInnerValue] = React.useState<any>(makeInnerValue(value))

  const cx = useStyles(styles)
  const container = cx(className, 'container')

  const handleClick = (e: any, day: string, time: any) => {
    const map = innerValue[day] ?? new Map()
    map.has(time) ? map.delete(time) : map.set(time, time)
    const newValue = { [day]: map }
    setInnerValue({ ...innerValue, ...newValue })
    onChange && onChange(makeValue({ ...innerValue, ...newValue }))
  }

  const isChecked = (day: any, time: any) => {
    if (!innerValue[day]) return false

    return innerValue[day].has(time)
  }

  React.useEffect(() => {
    setInnerValue(makeInnerValue(value))
  }, [value])

  return (
    <div data-testid="WeeklyTimePicker" className={container}>
      {days.map((day, index) => (
        <React.Fragment key={day.value}>
          <span className={cx('dayLabel', { weekdays: index < 6 })}>{day.label}</span>
          {times.map(time => (
            <React.Fragment key={`${day.value}-${time}`}>
              {day.label === '' ? (
                <span className={styles.timeLabel}>{time}</span>
              ) : (
                <RadioButton
                  key={`${day.value}-${time}-btn`}
                  className={styles.radioButton}
                  checked={isChecked(day.value, time)}
                  onClick={e => handleClick(e, day.value, time)}
                  onChange={e => e}
                />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  )
})

WeeklyTimePicker.defaultProps = {}

export default WeeklyTimePicker
