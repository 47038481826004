import React from 'react'
import { useStyles } from 'hooks/useStyles'
import Input from 'components/elements/Input'
import { ControlInputProps } from './ControlInput.types'

import styles from './ControlInput.module.scss'

const ControlInput = React.forwardRef<HTMLInputElement, ControlInputProps>((props, ref) => {
  const { error, className, ...restProps } = props

  const cx = useStyles(styles)

  const classNames = cx(className, 'container')

  return (
    <div data-testid="ControlInput" className={classNames}>
      <Input ref={ref} invalid={!!error} {...restProps} />
      <div className={styles.errorMessage}>{error}</div>
    </div>
  )
})

ControlInput.defaultProps = {}

export default ControlInput
