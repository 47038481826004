import { request } from 'api/request'

export default class AttachmentApi {
  static async imageUpload<T>(payload: T) {
    return request.post(`cmd/attachment/bannerImageUpload`, payload)
  }

  static async imageGet(params: string) {
    return request.get(`cmd/attachment/bannerImageGet?ids=${params}`, {})
  }
}
