import React from 'react'

import { useStyles } from 'hooks/useStyles'
import styles from './Loader.module.scss'

const Loader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const cx = useStyles(styles)
  const container = cx(className, 'container')

  return (
    <div data-testid="Loader" className={container}>
      <div className={cx('circle', 'circle-1')} />
      <div className={cx('circle', 'circle-2')} />
      <div className={cx('circle', 'circle-3')} />
      <div className={cx('circle', 'circle-4')} />
      <div className={cx('circle', 'circle-5')} />
    </div>
  )
}

export default Loader
