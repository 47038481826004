import { format, addDays } from 'date-fns'

/**
 * Common dates constants and functions
 */
export const dateFormat = 'yyyy-MM-dd'
export const dateWithTimeFormat = 'yyyy-MM-dd HH:mm:ss'
export const dateNow = new Date()
export const today = format(dateNow, dateFormat)
export const tomorrow = format(addDays(dateNow, 1), dateFormat)
export const yesterday = format(addDays(dateNow, -1), dateFormat)
export const sevenDaysEarly = format(addDays(dateNow, -7), dateFormat)
