import React from 'react'
import Button from 'components/elements/Button'

import brokenVase from 'static/images/brokenVase.png'

import style from './ErrorBoundary.module.scss'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  static componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={style.backdrop}>
          <div className={style.container}>
            <p className={style.title}>Ой, что-то сломалось</p>
            <div className={style.description}>
              Попробуйте
              <br />
              <Button className={style.button} variant="text" onClick={() => document.location.reload()}>
                перезагрузить страницу
              </Button>
            </div>
            <img className={style.image} src={brokenVase} alt="cat broke the vase" />
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
