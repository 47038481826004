import { RequiredAttributes } from 'types/general'

/** */
export const makeBannersData = (data: any[], campaignId: string) => {
  const campaignIndex = data.findIndex(o => String(o.extra?.campaignId) === campaignId)
  const extra = data[campaignIndex]?.extra

  if (extra?.state === 'collapse') {
    const banners = data[campaignIndex]?.banners || []
    const insert = banners.map((o: any) => {
      return {
        ...o,
        placeName: o.place.name,
        formatName: `${o.format.width}x${o.format.height}`,
        extra: { type: 'banner', bannerId: o.id },
        id: `banner-${o.id}`,
      }
    })
    extra.state = 'expand'

    return [...data.slice(0, campaignIndex + 1), ...insert, ...data.slice(campaignIndex + 1)]
  }

  if (extra?.state === 'expand') {
    const bannersLength = data[campaignIndex]?.banners.length
    extra.state = 'collapse'

    return [...data.slice(0, campaignIndex + 1), ...data.slice(campaignIndex + bannersLength + 1)]
  }

  return []
}

/** Extract campaign data to make flat table with statistic */
export const makeCampaignsData = (data: any[]) => {
  let dataWithCampaigns: Array<any> = []
  data.forEach(o => {
    dataWithCampaigns.push(o)
    if (o.campaigns.length) {
      const campaigns = o.campaigns.map((campaign: RequiredAttributes) => {
        return {
          ...campaign,
          ...{ extra: { type: 'campaign', campaignId: campaign.id, state: 'collapse' } },
          id: `campaign-${campaign.id}`,
        }
      })
      dataWithCampaigns.push(...campaigns)
    }
  })

  return dataWithCampaigns
}

/** Add to object values from nested object data with key 'value', useful for table data representation */
export const extractValues = (data: any[]) => {
  const dataWithValues = data.map(o => {
    for (let key in o.value) {
      o[key] = o.value[key]
    }

    return o
  })

  return dataWithValues
}

/** Keep table campaign state (collapsed or expanded) between table sort toggles */
export const saveState = (data: any[]) => {
  const state = data
    .filter(o => o?.extra?.type === 'campaign')
    .map(o => ({ id: o.extra.campaignId, state: o.extra.state }))

  return state
}

/** Restore table campaign state (collapsed or expanded) after table sort toggles */
export const restoreState = (data: any[], state: any[]) => {
  const restoredData = state.reduce((acc, o) => {
    if (o.state === 'expand') {
      acc = [...makeBannersData(acc, String(o.id))]
    }

    return acc
  }, data)

  return restoredData
}
