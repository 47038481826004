import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStyles } from 'hooks/useStyles'
import { PageFirstIcon, PagePrevIcon, PageNextIcon, PageLastIcon } from 'static/icons'

import ControlSelect from 'components/containers/ControllSelect'
import Button from 'components/elements/Button'
import Input from 'components/elements/Input'

import { PaginatorProps } from './Paginator.types'

import styles from './Paginator.module.scss'

const perPageSelectOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
]

const Paginator: React.FC<PaginatorProps> = ({ pagination, className, paginationFn }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const per = searchParams.get('per')

  const [perPage, setPerPage] = React.useState(per || 10)

  const cx = useStyles(styles)
  const paginator = cx(className, 'paginator')

  const isFirstPage = React.useCallback(() => {
    return pagination.current_page === 0
  }, [pagination.current_page])

  const isLastPage = React.useCallback(() => {
    return pagination.current_page + 1 === pagination.pages_count
  }, [pagination.current_page, pagination.pages_count])

  const fnInvoke = React.useCallback(
    (pagination_page, pagination_per_page) => {
      setSearchParams({ page: pagination_page, per: pagination_per_page })
      paginationFn && paginationFn({ pagination_page, pagination_per_page })
    },
    [paginationFn]
  )

  const handleFirstPage = () => fnInvoke(0, perPage)
  const handleNextPage = () => fnInvoke(pagination.current_page + 1, perPage)
  const handlePrevPage = () => fnInvoke(pagination.current_page - 1, perPage)
  const handleLastPage = () => fnInvoke(pagination.pages_count - 1, perPage)

  const handleChange = (value: number | string | undefined) => {
    if (value) {
      fnInvoke(0, value)
      setPerPage(value)
    }
  }

  return (
    <div className={paginator}>
      <div className={styles.controlBlock}>
        <span>{'Показано строк'}</span>
        <ControlSelect
          value={perPage}
          options={perPageSelectOptions}
          className={styles.perPageSelect}
          onChange={handleChange}
        />
        {pagination.per_page <= pagination.items_count && <span>{`из ${pagination.items_count}`}</span>}
      </div>
      <div className={styles.controlBlock}>
        <Button variant="secondary" className={styles.controlBtn} onClick={handleFirstPage} disabled={isFirstPage()}>
          <PageFirstIcon />
        </Button>
        <Button variant="secondary" className={styles.controlBtn} onClick={handlePrevPage} disabled={isFirstPage()}>
          <PagePrevIcon />
        </Button>
        <Input
          className={styles.currentPageInput}
          defaultValue={pagination.items_count ? pagination.current_page + 1 : pagination.current_page}
          readOnly
        />
        <span>{`из ${pagination.pages_count || ''}`}</span>
        <Button variant="secondary" className={styles.controlBtn} onClick={handleNextPage} disabled={isLastPage()}>
          <PageNextIcon />
        </Button>
        <Button variant="secondary" className={styles.controlBtn} onClick={handleLastPage} disabled={isLastPage()}>
          <PageLastIcon />
        </Button>
      </div>
    </div>
  )
}

Paginator.defaultProps = {}

export default Paginator
