import React from 'react'
import { UserDataContext } from 'providers/UserData/UserDataProvider'

/**
 * React hook to work along with UserData context provider
 *
 * @param none
 * @returns react context
 */

export function useUserData() {
  return React.useContext(UserDataContext)
}
