import React from 'react'

/**
 * React hook to handle click outside and inside specific Dom element
 *
 * @param ref - Dom element
 * @param handlerOutSideClick - handler of outside ref clicking
 * @param handlerInSideClick -  handler of inside ref clicking
 */

export function useOutsideClick(
  ref: HTMLElement | null,
  handlerOutSideClick: (e: Event) => void,
  handlerInSideClick?: (e: Event) => void
) {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref && ref.contains(e.target as HTMLElement)) {
        handlerInSideClick && handlerInSideClick(e)
      } else {
        handlerOutSideClick(e)
      }
    }

    ref && document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [ref, handlerOutSideClick, handlerInSideClick])
}
