import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { TextAreaProps } from './TextArea.types'

import styles from './TextArea.module.scss'

const TextArea: React.FC<TextAreaProps> = props => {
  const { invalid, className, ...restProps } = props
  const cx = useStyles(styles)

  const classNames = cx(className, 'container', {
    invalid: !!invalid,
  })

  return <textarea data-testid="TextArea" className={classNames} {...restProps} />
}

TextArea.defaultProps = {}

export default TextArea
