import React from 'react'
import Checkbox from 'components/elements/Checkbox'
import { useStyles } from 'hooks/useStyles'
import { arrayToMap, mapToArray } from 'lib/utils'
import { CheckboxConfig } from 'types/checkbox'
import { CheckboxGroupProps } from './CheckboxGroup.types'

import styles from './CheckboxGroup.module.scss'

const CheckboxGroup = React.forwardRef((props: CheckboxGroupProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { checkboxConfig, data, errorMessage, className, onChange } = props
  const [innerData, setInnerData] = React.useState<string[]>(data || [])

  const cx = useStyles(styles)
  const checkboxGroup = cx(className, 'checkboxGroup')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = e.target.value
    const innerDataMap = arrayToMap(innerData)

    if (innerDataMap.has(checkboxValue)) {
      innerDataMap.delete(checkboxValue)
    } else {
      innerDataMap.set(checkboxValue, true)
    }

    setInnerData(mapToArray(innerDataMap))
  }

  React.useEffect(() => {
    onChange(innerData)
  }, [innerData])

  return (
    <div className={styles.container} ref={ref}>
      <div className={checkboxGroup}>
        {checkboxConfig.map(({ name, label }: CheckboxConfig<string>) => (
          <Checkbox
            label={label}
            value={name}
            name={name}
            key={name}
            checked={!!innerData?.find(checkboxName => checkboxName === name)}
            onChange={handleChange}
          />
        ))}
      </div>
      <div className={styles.error}>{errorMessage}</div>
    </div>
  )
})

export default CheckboxGroup
