import React from 'react'
import { useStyles } from 'hooks/useStyles'

import { useStore } from 'hooks/useStore'

import { ActiveCellProps } from './ActiveCell.types'

import styles from './ActiveCell.module.scss'

const ActiveCell = React.forwardRef<HTMLDivElement, ActiveCellProps>((props, ref) => {
  const { value, column, className, ...restProps } = props

  const {
    statistic: { config },
  } = useStore()

  const cx = useStyles(styles)

  const container = cx(className, 'container', {
    active: config?.sort_field === column,
  })

  return (
    <span className={container} ref={ref} {...restProps}>
      {value}
    </span>
  )
})

export default ActiveCell
