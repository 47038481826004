import React from 'react'
import { today } from 'lib/dates'
import { getPreferSortDirection, setPreferSortDirection } from 'lib/store'

import { SortSettings, StoreProviderType } from './StoreProvider.types'
import { StatisticConfig, DateRangeConfig } from 'pages/statistic/statistic.types'

const initialConfig: StatisticConfig = {
  sites_ids: '',
  sort_field: 'loads',
  sort_direction: 'desc',
}

const initialDateRange: DateRangeConfig = {
  date_start: today,
  date_end: today,
}

/**
 * Common data store context provider
 *
 * @param children is a consumer of a context
 * @returns a context provider with common data
 */

export const StoreContext = React.createContext({} as StoreProviderType)

export const StoreProvider: React.FC = ({ children }) => {
  const [config, setConfig] = React.useState<StatisticConfig>(initialConfig)
  const [dateRange, setRange] = React.useState<DateRangeConfig>(initialDateRange)
  const [sortSettings, setSortSettings] = React.useState<SortSettings>(getPreferSortDirection())

  // TODO: рассмотреть возможность выноса в отдельную фукция для сравнения объектов
  const setDateRange = (range: DateRangeConfig) => {
    if (dateRange.date_start !== range.date_start || dateRange.date_end !== range.date_end) {
      setRange(range)
    }
  }

  React.useEffect(() => {
    setPreferSortDirection(sortSettings)
  }, [sortSettings])

  return (
    <StoreContext.Provider
      value={{ statistic: { config, setConfig, dateRange, setDateRange }, sort: { ...sortSettings, setSortSettings } }}
    >
      {children}
    </StoreContext.Provider>
  )
}
