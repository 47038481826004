import React from 'react'
import SessionApi from 'api/session'
import { readCookie } from 'lib/cookies'

import { AuthProviderType } from './AuthProvider.types'

const getSession = async () => {
  const token = readCookie('token')
  if (token) {
    const { response } = await SessionApi.get(token)

    if (response) return response.user_id
  }

  return false
}

/**
 * Authentication context provider
 *
 * @param children is a consumer of a context
 * @returns a context provider with authentication state, userId, callback to set authentication state, loading flag
 */

export const AuthContext = React.createContext({} as AuthProviderType)

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setInnerAuth] = React.useState<boolean>(false)
  const [userId, setUserId] = React.useState<number | undefined>(undefined)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const isFirstRender = React.useRef(true)

  if (isFirstRender.current) {
    setIsLoading(true)
    getSession().then(value => {
      setInnerAuth(!!value)
      setUserId(value || undefined)
      setIsLoading(false)
    })

    isFirstRender.current = false
  }

  const setAuth = (data: false | number) => {
    if (data) {
      setInnerAuth(true)
      setUserId(data)
    } else {
      setInnerAuth(false)
    }
  }

  return <AuthContext.Provider value={{ userId, auth, isLoading, setAuth }}>{children}</AuthContext.Provider>
}
