import React from 'react'
import { AuthContext } from 'providers/Auth/AuthProvider'

/**
 * React hook to work along with Authentication context provider
 *
 * @param none
 * @returns react context
 */

export function useAuth() {
  return React.useContext(AuthContext)
}
