import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes, namedRoute } from 'routes/routes'
import { BannerApi, CampaignApi, TargetingApi } from 'api'
import { makeOptions } from 'lib/utils'
import { useStore } from 'hooks/useStore'

import Breadcrumbs from 'components/containers/Breadcrumbs'
import { Tab, TabGroup } from 'components/containers/Tabs'
import CampaignsForm from 'components/forms/CampaignsForm'
import Paginator from 'components/containers/Table/Paginator'
import Button from 'components/elements/Button'
import Notification from 'components/elements/Notification'
import BannerTable from 'pages/banners/table/BannerTable'

import { CampaignData } from 'types/campaignData'
import { SelectOptions } from 'types/selectOptions'
import { PaginationData } from 'components/containers/Table/Paginator/Paginator.types'
import { BannerData } from 'types/bannerData'

import styles from '../campaigns.module.scss'

const ChangeCampaigns: React.FC = () => {
  const { campaignId } = useParams()
  const {
    sort: { banners: sortDirection },
  } = useStore()

  const [campaign, setCampaign] = React.useState<CampaignData | undefined>()
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<number | undefined>(Number(campaignId))
  const [targetingOptions, setTargetingOptions] = React.useState<SelectOptions[]>()
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0)
  const [bannerList, setBannerList] = React.useState<BannerData[] | null>(null)
  const [pagination, setPagination] = React.useState<PaginationData>()
  const [error, setError] = React.useState<string>('')

  const navigate = useNavigate()

  const getBanners = async (params = {}) => {
    const payload = { campaign_id: selectedCampaignId, ...params }
    const { response, error } = await BannerApi.list({ ...payload, sort: `id_${sortDirection}` })

    if (response) {
      setBannerList(response.items)
      setPagination(response.paginator)
    }

    error && setError(error.message)
  }

  const getCampaign = async (id: number) => {
    const { response, error } = await CampaignApi.get(id)

    response && setCampaign(response)
    error && setError(error.message)
  }

  const getTargeting = async (params = { pagination_per_page: 999 }) => {
    const { response, error } = await TargetingApi.list(params)

    response && setTargetingOptions(makeOptions(response.items, 'name', 'id'))
    error && setError(error.message)
  }

  const onSubmit = async (data: CampaignData) => {
    const { id, ...rest } = data
    const payload = { ...rest }

    const { response, error } = id ? await CampaignApi.update(id, payload) : await CampaignApi.add(payload)

    if (response) {
      setSelectedCampaignId(id || response.campaign_id)
      setActiveTabIndex(1)
      window.history.replaceState(
        null,
        '',
        namedRoute(routes.campaigns.edit.path, { campaignId: id || response.campaign_id })
      )
    }

    error && setError(error.message)
  }

  React.useEffect(() => {
    if (selectedCampaignId) {
      getCampaign(Number(selectedCampaignId))
      getBanners()
    }
    !targetingOptions && getTargeting()
  }, [selectedCampaignId, sortDirection])

  return (
    <>
      <Breadcrumbs
        route={campaignId ? routes.campaigns.edit : routes.campaigns.add}
        routeGroup={routes.campaigns}
        params={{ campaignId }}
        setError={setError}
      />
      <TabGroup defaultTabIndex={activeTabIndex}>
        <Tab title="Рекламная кампания">
          <CampaignsForm campaign={campaign} targetingOptions={targetingOptions} onSubmit={onSubmit} />
        </Tab>
        <Tab title="Баннеры" disable={!selectedCampaignId}>
          <Button
            onClick={() => navigate(namedRoute(routes.banners.addToCampaign.path, { campaignId: selectedCampaignId }))}
          >
            {'Добавить'}
          </Button>
          <BannerTable getBanners={getBanners} bannerList={bannerList} setError={setError} />
          {pagination && <Paginator pagination={pagination} paginationFn={getBanners} />}
        </Tab>
      </TabGroup>
      <Notification setError={setError}>{error}</Notification>
    </>
  )
}

export default ChangeCampaigns
