export const routes = {
  home: '/',
  login: '/login',
  users: '/users',
  sites: {
    index: {
      path: '/sites',
      breadcrumbName: 'Сайты',
      breadcrumbPath: 'index',
    },
    edit: {
      path: '/sites/edit/:siteId',
      breadcrumbName: 'Рекламные места на :site_name',
      breadcrumbPath: 'index/edit',
    },
  },
  places: {
    index: {
      path: '/places',
      breadcrumbName: 'Рекламные места',
      breadcrumbPath: 'index',
    },
    add: {
      path: '/places/add',
      breadcrumbName: 'Новое рекламное место',
      breadcrumbPath: 'index/add',
    },
    edit: {
      path: '/places/edit/:placeId',
      breadcrumbName: ':place_name',
      breadcrumbPath: 'index/edit',
    },
    addToSite: {
      path: '/places/add/:siteId',
      breadcrumbName: ':site_name',
      breadcrumbPath: 'index/addToSite',
    },
  },
  campaigns: {
    index: {
      path: '/campaigns',
      breadcrumbName: 'Рекламные кампании',
      breadcrumbPath: 'index',
    },
    add: {
      path: '/campaigns/add',
      breadcrumbName: 'Новая рекламная кампания',
      breadcrumbPath: 'index/add',
    },
    edit: {
      path: '/campaigns/edit/:campaignId',
      breadcrumbName: ':campaign_name',
      breadcrumbPath: 'index/edit',
    },
  },
  banners: {
    index: {
      path: '/banners',
      breadcrumbName: 'Баннеры',
      breadcrumbPath: 'index',
    },
    add: {
      path: '/banners/add',
      breadcrumbName: 'Новый баннер',
      breadcrumbPath: 'index/add',
    },
    addToCampaign: {
      path: '/banners/add/:campaignId',
      breadcrumbName: 'Новый баннер',
      breadcrumbPath: 'index/addToCampaign',
    },
    edit: {
      path: '/banners/edit/:bannerId',
      breadcrumbName: ':banner_name',
      breadcrumbPath: 'index/edit',
    },
  },
  targeting: {
    index: {
      path: '/targeting',
      breadcrumbName: 'Таргетинг',
      breadcrumbPath: 'index',
    },
    add: {
      path: '/targeting/add',
      breadcrumbName: 'Новый таргетинг',
      breadcrumbPath: 'index/add',
    },
    edit: {
      path: '/targeting/edit/:targetingId',
      breadcrumbName: ':targeting_name',
      breadcrumbPath: 'index/edit',
    },
  },
  statistic: {
    index: {
      path: '/statistic',
      breadcrumbName: 'Статистика',
      breadcrumbPath: 'index',
    },
  },
}

//TODO: зарефакторить функции
function preserveEndingSlash(pattern: string, reversed: string) {
  const endingSlashRe = /\/$/,
    shouldHave = endingSlashRe.test(pattern),
    has = endingSlashRe.test(reversed)

  if (shouldHave && !has) {
    return reversed + '/'
  } else if (!shouldHave && has) {
    return reversed.slice(0, reversed.length - 1)
  }

  return reversed
}

export function namedRoute(pattern: string, params: any = {}) {
  const reversed = pattern
    .replace(/\w*(:\w+\??)/g, function (path, param) {
      const key = param.replace(/[:?]/g, '')

      if (params[key] === undefined) {
        return param.indexOf('?') < 0 ? path : ''
      }

      return path.replace(param, params[key])
    })
    .replace(/\/\//, '/')

  return preserveEndingSlash(pattern, reversed)
}
