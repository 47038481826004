import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { PointsIcon } from 'static/icons'
import { makeStyles } from '../Table'

import { TableRowProps } from '../Table.types'
import { RequiredAttributes } from 'types/general'

import style from 'components/containers/Table/Table.module.scss'

interface TRow extends RequiredAttributes {
  /** Additional data information for the cell custom render */
  extra?: string
}

const TableRow = <T extends TRow>(props: TableRowProps<T>) => {
  const { tConfig, row, controlRef, withControl, handleClick, handleAction } = props
  const cx = useStyles(style)

  return (
    <tr data-testid="TableRow">
      {tConfig.map((cell, index) =>
        cell.render ? (
          <td className={cx(makeStyles(cell.align))} key={index}>
            {cell.render(row[cell.name], row.extra)}
          </td>
        ) : (
          <td
            className={cx(makeStyles(cell.align, cell.actionOptionsIndex))}
            key={index}
            onClick={e => handleAction(e, row.id, cell.actionOptionsIndex)}
          >
            {row[cell.name]}
          </td>
        )
      )}
      {withControl && (
        <td className={style['align-right']}>
          <span ref={controlRef} className={style.pointIcon} onClick={e => handleClick(e, row)}>
            <PointsIcon data-hover="fill" />
          </span>
        </td>
      )}
    </tr>
  )
}

export default TableRow
