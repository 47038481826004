import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { CrossIcon } from 'static/icons'
import { NotificationProps } from './Notification.types'

import styles from './Notification.module.scss'

const Notification: React.FC<NotificationProps> = ({ type, duration, className, children, setError, ...restProps }) => {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const cx = useStyles(styles)

  const notification = cx(className, 'container', {
    [`type-${type}`]: type,
    active: isActive,
  })

  const onClose = () => {
    setIsActive(false)
    setError('')
  }

  React.useEffect(() => {
    isActive && setTimeout(() => onClose(), duration)
  }, [isActive])

  React.useEffect(() => {
    !!children && setIsActive(true)
  }, [children])

  return (
    <div data-testid="Notification" className={notification} {...restProps}>
      <span className={styles.close} onClick={onClose}>
        <CrossIcon />
      </span>
      {children}
    </div>
  )
}

Notification.defaultProps = {
  type: 'error',
  duration: 5000,
}

export default Notification
