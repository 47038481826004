import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, FieldValues, useForm } from 'react-hook-form'

import ControlInput from 'components/containers/ControlInput'
import ControlSelect from 'components/containers/ControllSelect'
import Button from 'components/elements/Button'
import RadioButton from 'components/elements/RadioButton'
import Tooltip from 'components/elements/Tooltip'

import { Size } from 'types/size'
import { PlaceData } from 'types/placeData'
import { PlacesFormType } from './PlacesForm.types'

import styles from './PlacesForm.module.scss'

const PlacesForm: React.FC<PlacesFormType> = ({
  siteOptions,
  campaignOptions,
  place,
  campaignId,
  formats,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm()

  const [template, setTemplate] = React.useState('')
  const [widthType, setWidthType] = React.useState('px')

  const navigate = useNavigate()
  const { siteId } = useParams()

  const handleTemplateClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const template = e.target.value
    setTemplate(template)
    template.split('х').map((size, index) => setValue(Size[index], size))
    clearErrors(Object.keys(Size).filter(x => !(parseInt(x) >= 0)))
    setWidthType('px')
  }

  const handleChangeSize = (e: React.ChangeEvent<HTMLInputElement>, field: FieldValues) => {
    setTemplate('')
    field.onChange(e.target.value)
  }

  const handleWidthTypeClick = () => {
    setTemplate('')
    if (widthType === 'px') {
      setWidthType('content')
      setValue('width', '100')
    } else {
      setWidthType('px')
      setValue('width', '')
    }
  }

  const submitInterceptor = (data: PlaceData) => {
    //@ts-ignore
    onSubmit({ ...data, width_size_type: widthType })
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(submitInterceptor)} className={styles.form}>
        <label className={styles.label}>{'Название'}</label>
        <Controller
          defaultValue={place?.id ? place?.name : ''}
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <ControlInput placeholder="Введите название" {...field} error={errors.name ? 'Обязательное поле' : ''} />
          )}
        />

        {place?.id ? (
          <>
            <label className={styles.label}>{'Формат'}</label>
            <Controller
              name="format"
              control={control}
              render={({ field }) => (
                <ControlInput
                  {...field}
                  disabled
                  value={`${place.format.width || '100%'} x ${place.format.height || '100%'}`}
                />
              )}
            />
          </>
        ) : (
          <>
            <div className={styles.tooltip}>
              {
                'Cайт и размеры нельзя будет изменить в будущем — они задаются только в момент создания рекламного места'
              }
            </div>

            <label className={styles.label}>{'Выберите сайт'}</label>
            <Controller
              defaultValue={siteId ? siteId : ''}
              name="site_id"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ControlSelect
                  disabled={!!siteId}
                  options={siteOptions}
                  placeholder="Выбрать сайт"
                  {...field}
                  error={errors.site_id ? 'Обязательное поле' : ''}
                />
              )}
            />
            <div className={styles.format}>
              <div className={styles.sizes}>
                <div className={styles.widthLabel}>
                  <p>{'Ширина рекламного места'}</p>
                  <Tooltip>{'По клику на px и % выполняется\n переключение форматов'}</Tooltip>
                </div>
                <div className={styles.size}>
                  <Controller
                    name="width"
                    defaultValue={''}
                    control={control}
                    rules={{
                      required: 'Обязательное поле',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Введите число',
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <ControlInput
                          {...field}
                          className={styles.formatInput}
                          disabled={widthType === 'content'}
                          onChange={e => handleChangeSize(e, field)}
                          error={errors.width && errors.width.message}
                        />
                      )
                    }}
                  />
                  <Button className={styles.widthType} variant="secondary" type="button" onClick={handleWidthTypeClick}>
                    {widthType === 'px' ? 'px' : '%'}
                  </Button>
                </div>

                <p className={styles.formatLabel}>{'Высота рекламного места'}</p>
                <div className={styles.size}>
                  <Controller
                    name="height"
                    defaultValue={''}
                    control={control}
                    rules={{
                      required: 'Обязательное поле',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Введите число',
                      },
                    }}
                    render={({ field }) => {
                      return (
                        <ControlInput
                          {...field}
                          className={styles.formatInput}
                          onChange={e => handleChangeSize(e, field)}
                          error={errors.height && errors.height.message}
                        />
                      )
                    }}
                  />
                  <span className={styles.heightType}>{'px'}</span>
                </div>
              </div>

              <div>
                <p className={styles.formatLabel}>{'Шаблоны'}</p>
                <div className={styles.template}>
                  {formats?.map(format => (
                    <RadioButton
                      key={format}
                      value={format}
                      checked={template === format}
                      onChange={handleTemplateClick}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        <label className={styles.label}>{'Рекламная кампания по умолчанию'}</label>
        <Controller
          defaultValue={campaignId}
          name="campaign_id"
          control={control}
          render={({ field }) => (
            <ControlSelect clearable options={campaignOptions} placeholder="Выбрать кампанию" {...field} />
          )}
        />

        <div className={styles.buttons}>
          <Button type="button" onClick={() => navigate(-1)} variant="secondary">
            {'Отмена'}
          </Button>
          <Button type="submit">{place?.id ? 'Сохранить' : 'Создать'}</Button>
        </div>
      </form>
    </div>
  )
}

export default PlacesForm
