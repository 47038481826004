import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import Button from 'components/elements/Button'
import ControlInput from 'components/containers/ControlInput'
import ControlSelect from 'components/containers/ControllSelect'
import RadioButton from 'components/elements/RadioButton'
import BannerTypeSwitcher from './BannerTypeSwitcher'

import { bannerTypes } from 'constant/bannerTypes'
import { IS_NEW_WINDOW } from 'constant/common'

import { BannersFormProps } from './BannersForm.types'
import { BannerType } from 'types/bannerData'

import styles from './BannersForm.module.scss'

const imageFields = ['image', 'click_url']

const BannersForm: React.FC<BannersFormProps> = ({
  bannerData,
  campaignOptions,
  targetingOptions,
  formatOptions,
  error,
  onImageSelect,
  onSubmit,
}) => {
  const navigate = useNavigate()
  const { campaignId } = useParams()
  const {
    control,
    watch,
    setError,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm()

  const [bannerType, setBannerType] = React.useState<BannerType>(bannerData ? undefined : 'image')
  const imageFile = watch('image')

  const resetFieldHandler = () => {
    bannerType === 'html' ? imageFields.map(fieldName => resetField(fieldName)) : resetField('code')
  }

  const handleDecline = () => {
    if (window.top?.name === IS_NEW_WINDOW) {
      // Close a window if it was opened in a new tab (Ctrl-click)
      window.close()

      return
    }

    navigate(-1)
  }

  React.useEffect(() => {
    error && setError('field', { type: 'api', message: error })
  }, [error])

  React.useEffect(() => {
    bannerType && resetFieldHandler()
  }, [bannerType])

  React.useEffect(() => {
    imageFile && onImageSelect && onImageSelect(imageFile)
  }, [imageFile])

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <label className={styles.label}>{'Название баннера'}</label>
        <Controller
          defaultValue={bannerData?.name || ''}
          name="name"
          control={control}
          rules={{ required: 'Обязательное поле' }}
          render={({ field }) => (
            <ControlInput placeholder="Введите название" {...field} error={errors.name?.message} />
          )}
        />

        {bannerData ? (
          <BannerTypeSwitcher control={control} errors={errors} bannerData={bannerData} bannerType={bannerData.type} />
        ) : (
          <>
            <label className={styles.label}>{'Тип баннера'}</label>
            <div className={styles.variants}>
              {bannerTypes.map(type => (
                <RadioButton
                  key={type.name}
                  value={type.name}
                  label={type.label}
                  checked={bannerType === type.name}
                  onChange={() => setBannerType(type.name as BannerType)}
                />
              ))}
            </div>
            <BannerTypeSwitcher control={control} errors={errors} bannerType={bannerType} />

            <label className={styles.label}>{'Рекламная кампания'}</label>
            <Controller
              defaultValue={campaignId || ''}
              name="campaign_id"
              control={control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <ControlSelect
                  options={campaignOptions}
                  placeholder="Выбрать кампанию"
                  error={errors.campaign_id?.message}
                  {...field}
                />
              )}
            />
          </>
        )}

        <label className={styles.label}>{'Формат'}</label>
        <Controller
          defaultValue={bannerData?.format_id || ''}
          name="format_id"
          control={control}
          rules={{ required: 'Обязательное поле' }}
          render={({ field }) =>
            bannerData ? (
              <ControlInput
                disabled
                {...field}
                value={`${bannerData.format.width || '100%'} x ${bannerData.format.height || '100%'}`}
                error={errors.format_id?.message}
              />
            ) : (
              <ControlSelect
                {...field}
                options={formatOptions}
                placeholder="Выбрать формат"
                error={errors.format_id?.message}
              />
            )
          }
        />

        <label className={styles.label}>{'Таргетинг'}</label>
        <Controller
          defaultValue={bannerData?.targeting_id}
          name="targeting_id"
          control={control}
          render={({ field }) => (
            <ControlSelect
              clearable
              options={targetingOptions}
              placeholder="Выбрать таргетинг"
              {...field}
              error={errors.targeting_id?.message}
            />
          )}
        />

        <div className={styles.buttons}>
          <Button type="button" onClick={handleDecline} variant="secondary">
            {'Отмена'}
          </Button>
          <Button type="submit">{!bannerData ? 'Создать' : 'Сохранить'}</Button>
        </div>
      </form>
    </div>
  )
}

export default BannersForm
