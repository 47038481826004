import React from 'react'
import { AttachmentApi, BannerApi } from 'api'
import { addPreview } from 'lib/preview'
import { useStyles } from 'hooks/useStyles'
import { ArrowDownIcon, EyeIcon } from 'static/icons'

import Viewer from 'components/containers/Viewer'

import { BannerData } from 'types/bannerData'
import { StatisticCellProps } from './StatisticCell.types'

import styles from './StatisticCell.module.scss'

const StatisticCell = React.forwardRef<HTMLDivElement, StatisticCellProps>((props, ref) => {
  const { value, extra, className, ...restProps } = props
  const { type = 'site', campaignId, bannerId, state } = extra

  const [triggerElement, setTriggerElement] = React.useState<HTMLElement>()
  const [activePreview, setActivePreview] = React.useState<boolean>(false)
  const [banner, setBanner] = React.useState<BannerData>()
  const [imageUrl, setImageUrl] = React.useState<string>()

  const getImage = async (id: string) => {
    const { response, error } = await AttachmentApi.imageGet(id)

    response && setImageUrl(response.items[0].url)
    //error && setError(error.message)
  }

  const getBanner = async (id: number) => {
    const { response, error } = await BannerApi.get(id)

    if (response) {
      setBanner(response)
      response.type === 'image' && getImage(response.code)

      return response
    }

    //error && setError(error.message)
  }

  const cx = useStyles(styles)

  const container = cx(className, 'container')
  const cell = cx('ellipsis', {
    [`${type}Cell`]: type,
  })
  const arrow = cx('arrow', {
    expand: state === 'expand',
  })

  const handlePreviewOn = (e: React.MouseEvent, id?: number) => {
    if (!id) return
    getBanner(id)
    setActivePreview(true)
    setTriggerElement(e.target as HTMLElement)
  }

  const handlePreviewOff = () => {
    setBanner(undefined)
    setImageUrl(undefined)
    setActivePreview(false)
  }

  React.useEffect(() => {
    activePreview && banner && addPreview(banner, imageUrl)
  }, [activePreview, banner, imageUrl])

  return (
    <>
      <div className={container} ref={ref} {...restProps}>
        {type === 'banner' && (
          <EyeIcon
            data-bannerid={bannerId}
            data-hover="stroke"
            className={styles.eye}
            onMouseEnter={e => handlePreviewOn(e, bannerId)}
            onMouseLeave={handlePreviewOff}
          />
        )}
        <div data-campaignid={campaignId} className={cell}>
          {value}
        </div>
        {type === 'campaign' && <ArrowDownIcon data-hover="stroke" data-campaignid={campaignId} className={arrow} />}
      </div>

      <Viewer triggerElement={triggerElement} visible={activePreview} height={banner?.format.height} />
    </>
  )
})

export default StatisticCell
